/* eslint-disable max-lines */
import {
  cleanChangedAndNotSelectedNodes,
  cleanChangedNodes,
  evaluateAsyncResults,
  fetchAllCategories,
  fetchMetricsData,
  fetchModelCategories,
  fetchTestCaseSummary,
} from 'actions';
import {
  selectAnnotators,
  setCurrentTestRuleId,
  setShowUtilization,
  updateRule,
} from 'actions/ruleGroup';
import LeavePageModal from 'components/LeavePageModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import RelationshipManager from 'components/RelationshipManagerRevision';
import keyMap from 'constants/configHotKeys';
import React, { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCategoriesList } from 'selectors/categories';
import { getModifiedNodes, getSelectedNode } from 'selectors/config';
import { getRuleCustomers } from 'selectors/ruleGroup';
import {
  getFetchSingleRuleGroupLoading,
  getSelectedRuleGroup,
  getSelectedRuleRevision,
} from 'selectors/ruleGroups';
import { getTestCasesResultsKey } from 'selectors/testCases';
import { useSelector } from 'store';
import ModelManagerContent from './ModelManagerContent';
import RelationshipManagerAdvancedModal from './RelationshipManagerAdvancedModal';
import ModelManagerSidebar from './Sidebar/ModelManagerSidebar';
import ModelManagerTestSuiteSidebar from './TestSuiteSidebar';

type RouterParams = { ruleId: string; campaignId?: string };

const ModelManager: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { ruleId } = useParams<RouterParams>();
  const modifiedNodes = useSelector(getModifiedNodes);
  const ruleGroup = useSelector(getSelectedRuleGroup);
  const ruleRevision = useSelector(getSelectedRuleRevision);
  const evaluateTestResultsKey = useSelector(getTestCasesResultsKey);
  const customers = useSelector(getRuleCustomers);
  const loading = useSelector(getFetchSingleRuleGroupLoading);
  const ruleCategory = useSelector(getCategoriesList)[0];
  const selectedNode = useSelector(getSelectedNode);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [advancedModal, setAdvancedModal] = useState(false);
  const [testSuiteSidebar, setTestSuiteSidebar] = useState(false);

  const { pathname } = location;

  const widgetId = `${pathname}--rule-group-heatmap--rule_branch_sent_at--0--${ruleId}`;
  const widgetIdCustomer = `${pathname}--rule-group-heatmap--rule_branch_sent_at--0--${ruleId}--customer`;

  const action = {
    metrics: [
      {
        id: ruleId,
        namespace: 'Litlingo',
        metric: 'total',
        label: 'Rules',
        group_by: 'branch',
        dimensions: [{ value: ruleId, name: 'rule_group' }],
      },
    ],
    fetchOnePeriod: true,
    fetchAvgPeriod: false,
    widgetId,
    timeRange: '-9999days',
    metricType: 'rule_branch_sent_at',
    use_cache: false,
    customer_uuids: customers.map((c) => c.uuid),
  };

  const customerGroupAction = {
    metrics: [
      {
        id: ruleId,
        namespace: 'Litlingo',
        metric: 'total',
        label: 'Rules',
        group_by: ['branch', 'customer'],
        dimensions: [{ value: ruleId, name: 'rule_group' }],
      },
    ],
    fetchOnePeriod: true,
    fetchAvgPeriod: false,
    widgetId: widgetIdCustomer,
    timeRange: '-9999days',
    metricType: 'rule_branch_sent_at',
    use_cache: false,
    customer_uuids: customers.map((c) => c.uuid),
  };

  useEffect(() => {
    dispatch(setCurrentTestRuleId(''));
    dispatch(setShowUtilization(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId]);

  useEffect(() => {
    if (ruleId) {
      dispatch(
        fetchAllCategories({
          rule_uuids: [ruleId],
          include_staging: 'true',
          relationships: ['outcome', 'model', 'rule_head'],
        })
      );
    }
  }, [dispatch, ruleRevision?.uuid, ruleId]);

  useEffect(() => {
    if (ruleCategory) {
      dispatch(fetchModelCategories({ modelId: ruleCategory.model_uuid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId, ruleCategory?.model_uuid]);

  useEffect(
    () => {
      if (selectedNode || selectedNode === 0) {
        // @ts-ignore
        dispatch(fetchMetricsData(customerGroupAction));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedNode, ruleId]
  );

  useEffect(() => {
    dispatch(selectAnnotators([]));
    if (customers.length > 0) {
      dispatch(fetchMetricsData(action));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleId, customers]);
  // @ts-ignore
  useEffect(() => (): void => dispatch(cleanChangedNodes()), [dispatch]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (evaluateTestResultsKey) {
      dispatch(evaluateAsyncResults(evaluateTestResultsKey));
      dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      intervalId = setInterval(() => {
        dispatch(evaluateAsyncResults(evaluateTestResultsKey));
        dispatch(fetchTestCaseSummary({ rule_uuids: ruleId }));
      }, 2000);
    }
    return (): void => clearInterval(intervalId);
  }, [dispatch, evaluateTestResultsKey, ruleId]);

  const toggleShowModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveRelationship = (): void => {
    if (ruleRevision) {
      dispatch(updateRule({ rule: ruleRevision }));
      dispatch(cleanChangedAndNotSelectedNodes());
    }
  };

  const handleSaveRelationshipAndCloseModal = (): void => {
    if (ruleRevision) {
      dispatch(updateRule({ rule: ruleRevision }));
      dispatch(cleanChangedNodes());
      setIsModalOpen(!isModalOpen);
    }
  };

  const handlers = {
    SAVE_RULE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        const button = document.querySelector('#save-rule-button') as HTMLButtonElement;
        if (button) button.click();
      }
    },
  };

  const handleToggleTestSuiteSidebar = (): void => setTestSuiteSidebar(!testSuiteSidebar);

  const renderRelationship = (): JSX.Element | null => {
    if (!ruleRevision) return null;

    return (
      <RelationshipManager setAdvancedModal={setAdvancedModal} advancedModal={advancedModal} />
    );
  };

  const renderRelationshipModal = (): JSX.Element => {
    if (!advancedModal) {
      return (
        <Modal
          body={renderRelationship()}
          title="Edit relationship: Same sentence"
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveRelationshipAndCloseModal}
          toggleShowModal={toggleShowModal}
        />
      );
    }
    return (
      <RelationshipManagerAdvancedModal
        body={renderRelationship()}
        title="Edit relationship: Same sentence"
        okButton
        okButtonText="Save and close"
        okButtonOnClick={handleSaveRelationshipAndCloseModal}
        saveButtonOnClick={handleSaveRelationship}
        toggleShowModal={toggleShowModal}
        setAdvancedModal={setAdvancedModal}
      />
    );
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (!ruleGroup || !ruleRevision) return <LoadingIndicator className="m-auto mt-20" size="20" />;
  if (loading && ruleGroup.uuid !== ruleId) {
    return <LoadingIndicator className="m-auto mt-20" size="20" />;
  }

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div
        className="flex flex-col bg-white min-w-screen"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <GlobalNavbar />
        <div className="flex flex-row h-full overflow-hidden">
          <div className="test-right-sidebar border-r flex flex-col h-full border-l border-litlingo-gray-2 overflow-auto no-scrollbar">
            {testSuiteSidebar ? (
              <ModelManagerTestSuiteSidebar
                toggleTestSuiteSidebar={handleToggleTestSuiteSidebar}
                ruleId={ruleId}
              />
            ) : (
              <ModelManagerSidebar rule={ruleRevision} />
            )}
          </div>

          <div className="flex flex-col gap-4 h-full w-full overflow-hidden">
            <main className="flex flex-col justify-between h-full overflow-auto no-scrollbar min-w-full">
              <ModelManagerContent
                ruleId={ruleId}
                rule={ruleRevision}
                toggleShowModal={toggleShowModal}
                toggleTestSuiteSidebar={handleToggleTestSuiteSidebar}
              />
            </main>
          </div>
        </div>

        {isModalOpen && renderRelationshipModal()}

        <LeavePageModal shouldBlockNavigation={modifiedNodes.length > 0} />
      </div>
    </>
  );
};

export default ModelManager;
