import { cleanChangedNodes } from 'actions';
import { promoteRule, updateRule } from 'actions/ruleGroup';
import Modal from 'components/Modal';
import { MODAL_BACKGROUND } from 'constants/common';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesList } from 'selectors/categories';
import { getCompareMode, getSelectedCustomerRuleConfig } from 'selectors/config';
import { getSelectedCustomerGroup } from 'selectors/ruleGroup';
import { useSelector } from 'store';
import type { MRuleRevision } from 'types';

type ComponentProps = {
  rule: MRuleRevision;
};

const RuleManagerSaveButton: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();

  const compareMode = useSelector(getCompareMode);
  const customerGroup = useSelector(getSelectedCustomerGroup);
  const selectedCustomer = useSelector(getSelectedCustomerRuleConfig);
  const categories = useSelector(getCategoriesList);

  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);

  const customerCategories = categories.filter((c) => c.customer_uuid === selectedCustomer || '');

  const prodCategory = customerCategories.find((c) => c.rule_head?.name === 'production');
  const stagingCategory = customerCategories.find((c) => c.rule_head?.name === 'staging');

  const shouldPromote = compareMode || (stagingCategory && !prodCategory);

  const toggleModal = (): void => {
    setIsPromoteModalOpen((b) => !b);
  };

  const handleSaveClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.stopPropagation();
    if (shouldPromote) {
      toggleModal();
    } else {
      dispatch(updateRule({ rule }));
      dispatch(cleanChangedNodes());
    }
  };

  const handlePromoteRule = (): void => {
    if (stagingCategory && stagingCategory.rule_head) {
      dispatch(
        promoteRule({ ruleGroup: rule.rule_group_uuid, head: stagingCategory.rule_head.uuid })
      );
      dispatch(cleanChangedNodes());
    }
    toggleModal();
  };

  const renderBody = (): JSX.Element => (
    <>
      <div className="absolute top-5 left-0 right-0 h-40 z-0">{MODAL_BACKGROUND}</div>

      <div className="flex flex-col mt-30 mb-8 gap-2">
        <h2 className="text-heading-1">Promote Rule</h2>
        <p className="text-body">Are you sure you want to promote this rule to production?</p>
      </div>
    </>
  );

  return (
    <>
      <button
        type="button"
        onClick={(evt): void => handleSaveClick(evt)}
        className="button button--primary text-body h-8 justify-center"
        data-testid="rule-mananger-save"
        id="save-rule-button"
        disabled={customerGroup === 'production'}
      >
        <span className="text-white font-bold ">
          {shouldPromote ? 'Promote to Production' : 'Save'}
        </span>
      </button>
      {isPromoteModalOpen && (
        <Modal
          title=" "
          body={renderBody()}
          okButton
          okButtonText="Promote"
          okButtonOnClick={handlePromoteRule}
          okButtonStyle="whitespace-no-wrap w-full px-4 mr-4"
          cancelButtonText="Cancel"
          cancelButtonOnclick={(): void => setIsPromoteModalOpen(false)}
          toggleShowModal={toggleModal}
        />
      )}
    </>
  );
};

export default RuleManagerSaveButton;
