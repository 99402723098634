import { selectAnnotators } from 'actions/ruleGroup';
import CollapsableSection from 'components/CollapsableSection';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getCompareMode } from 'selectors/config';
import {
  getCompareIdentifiers,
  getSelectedIdentifiers,
  getSelectedRuleIdentifiers,
} from 'selectors/ruleGroup';
import { useSelector } from 'store';

const RuleAnnotatorBank: React.FC = () => {
  const dispatch = useDispatch();

  const identifiers = useSelector(getSelectedRuleIdentifiers);
  const selectedAnnotators = useSelector((state) => getSelectedIdentifiers(state));

  const { added, removed, changed } = useSelector(getCompareIdentifiers);
  const compareMode = useSelector(getCompareMode);

  const handleSelectAnnotator = (annotatorId: string | undefined): void => {
    if (annotatorId) {
      if (selectedAnnotators.includes(annotatorId)) {
        const newAnnotators = selectedAnnotators.filter((ann) => ann !== annotatorId);
        dispatch(selectAnnotators(newAnnotators));
      } else {
        dispatch(selectAnnotators([...selectedAnnotators, annotatorId]));
      }
    }
  };

  const getIdentifierBgColor = (id: string): string => {
    if (compareMode) {
      if (selectedAnnotators.includes(id)) {
        if (added.includes(id)) {
          return 'bg-litlingo-green-text text-white';
        }
        if (removed.find((r) => r.identifier_uuid === id)) {
          return 'bg-litlingo-alert text-white';
        }
        if (changed.includes(id)) {
          return 'bg-litlingo-secondary-100 text-white';
        }
        return 'bg-litlingo-gray-4 text-white';
      }

      if (added.includes(id)) {
        return 'bg-litlingo-success-light text-litlingo-gray-6';
      }
      if (removed.find((r) => r.identifier_uuid === id)) {
        return 'bg-litlingo-alert-light text-litlingo-gray-6';
      }
      if (changed.includes(id)) {
        return 'bg-litlingo-warning-light text-litlingo-gray-6';
      }
      return 'bg-litlingo-gray-1 text-litlingo-gray-6';
    }

    if (selectedAnnotators.includes(id)) {
      return 'bg-litlingo-green-text text-white';
    }
    return 'bg-litlingo-green-bg text-litlingo-gray-6';
  };

  return (
    <CollapsableSection
      headerTitle="Identifier Bank"
      headerClasses="w-full h-8 px-4 flex flex-row justify-between items-center bg-litlingo-gray-1"
      contentClasses="relative mb-2 p-4"
      titleClasses="text-body"
      isDefaultClosed
    >
      <div className="flex flex-col mb-2">
        <div className="flex flex-row justify-between">
          <div>
            {/* <label htmlFor="description" className="flex flex-row gap-1 items-center">
            <div className="flex flex-row gap-1">
              <span>Show relevant</span>
            </div>
            <input
              id="diplay_in_assignments"
              name="diplay_in_assignments"
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
            />
          </label> */}
          </div>
          {identifiers && identifiers?.length > 0 && (
            <button
              type="button"
              aria-labelledby="clear-selection"
              className={`focus:outline-none ${
                selectedAnnotators.length > 0 ? 'text-litlingo-gray-6' : ' text-litlingo-gray-2'
              }`}
              onClick={(): { payload: string[]; type: string } => dispatch(selectAnnotators([]))}
              disabled={selectedAnnotators.length === 0}
            >
              <span id="clear-selection" className=" underline">
                Clear Selection
              </span>
            </button>
          )}
        </div>
        <div data-testid="container" className="flex flex-row flex-grow-0 flex-wrap gap-1 mt-1">
          {[...identifiers, ...(compareMode ? removed : [])]?.map((ann) => {
            const { identifier } = ann;
            return (
              <button
                key={identifier?.uuid}
                type="button"
                className={`rounded text-xss leading-4 px-1 focus:outline-none ${getIdentifierBgColor(
                  identifier?.uuid || ''
                )}`}
                onClick={(): void => handleSelectAnnotator(identifier?.uuid)}
              >
                <span>{identifier?.name}</span>
              </button>
            );
          })}
        </div>
      </div>
    </CollapsableSection>
  );
};

export default RuleAnnotatorBank;
