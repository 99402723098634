/* eslint-disable camelcase */
/* eslint-disable max-lines */
import { selectCustomerConfig, selectItem, showErrorAlert, validateAndMove } from 'actions';
import { setShowUtilization } from 'actions/ruleGroup';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getActiveTriggeredNodes, getTriggeredNodes } from 'selectors/communications';
import { getCompareMode, getSelectedNode } from 'selectors/config';
import {
  getConfigRule,
  getRuleIdentifierPosition,
  getSelectedCustomerGroup,
  getSelectedIdentifiers,
} from 'selectors/ruleGroup';
import { getShowRuleHighlights } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import type { MRuleConfig } from 'types';
import isSameSentenceApplicable from 'utils/configValidation';
import MakeDraggable from './MakeDraggable';
import RowItem from './RowItem';
import { operatorName } from './RuleManagerUtils';

type ComponentProps = {
  idToMove: string | null;
  setIdToMove: React.Dispatch<React.SetStateAction<string | null>>;
  collapseLevel: number;
  setLevelIsModified: React.Dispatch<React.SetStateAction<boolean>>;
  setCollapseLevel: React.Dispatch<React.SetStateAction<number>>;
  collapsedFolder: string[];
  setCollapsedFolder: React.Dispatch<React.SetStateAction<string[]>>;
  collapsedItems: string[];
  setCollapsedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setIsCollapsedAll: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const ModelManagerTable: React.FC<ComponentProps> = ({
  idToMove,
  setIdToMove,
  collapseLevel,
  setLevelIsModified,
  setCollapseLevel,
  collapsedFolder,
  setCollapsedFolder,
  collapsedItems,
  setCollapsedItems,
  setIsCollapsedAll,
}) => {
  const dispatch = useDispatch();
  const arrayTree = useSelector(getConfigRule);
  const prevUncollapsedAnnotators = useRef<string[]>([]);

  const selectedNode = useSelector(getSelectedNode);
  const [showTransition, setShowTransition] = useState(false);
  const [firstCollapse, setFirstCollapse] = useState(false);
  const [isDefaultView, setIsDefaultView] = useState(false);

  const selectedAnnotators = useSelector(getSelectedIdentifiers);
  const triggeredNodes = useSelector(getTriggeredNodes);
  const showHighlights = useSelector(getShowRuleHighlights);
  const triggeredNodesToUncollapse = useSelector(getActiveTriggeredNodes);
  const annotatorPosition = useSelector(getRuleIdentifierPosition);

  const compareMode = useSelector(getCompareMode);
  const inProd = useSelector(getSelectedCustomerGroup) === 'production';

  useEffect(() => {
    dispatch(selectItem({ index: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (compareMode) {
      setCollapseLevel(100);
    }
  }, [compareMode, setCollapseLevel]);

  useEffect(() => {
    if (collapseLevel === -1) {
      return;
    }

    const collapseItems: string[] = [];
    const collapseFolders: string[] = [];

    arrayTree.forEach((node) => {
      const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

      if (node.level > collapseLevel) {
        collapseItems.push(node.id);

        if (isFolder) {
          collapseFolders.push(node.id);
        }
      } else if (node.level === collapseLevel) {
        if (isFolder) {
          collapseFolders.push(node.id);
        }
      }
    });

    setCollapsedItems(collapseItems);
    setCollapsedFolder(collapseFolders);
    setIsDefaultView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseLevel]);

  useEffect(() => {
    const isLevelModified = arrayTree.some(
      (node) =>
        (node.level > collapseLevel &&
          !collapsedFolder.includes(node.id) &&
          !collapsedItems.includes(node.id)) ||
        (node.level < collapseLevel &&
          (collapsedFolder.includes(node.id) || collapsedItems.includes(node.id)))
    );
    if (isLevelModified) {
      setLevelIsModified(true);
      setCollapseLevel(-1);
    } else {
      setLevelIsModified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedItems.length, collapsedFolder.length]);

  useEffect(() => {
    if (annotatorPosition) {
      const element = document.getElementById(annotatorPosition);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [annotatorPosition, collapsedItems.length, collapsedFolder.length, collapseLevel]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id } = event.target;
    const nodeIndex = parseInt(id.split('-')[1], 10);
    const clickOnSameNode = nodeIndex === selectedNode;
    setShowTransition(false);
    if (clickOnSameNode) {
      dispatch(selectItem({ index: -1 }));
      dispatch(setShowUtilization(true));
      dispatch(selectCustomerConfig(''));
    } else {
      dispatch(selectItem({ index: nodeIndex }));
      setIsCollapsedAll(collapsedFolder.includes(arrayTree[nodeIndex].id));
    }
  };

  const handleCollapse = (id: string, isRoot = true): void => {
    const node = arrayTree.find((n) => n.id === id);
    const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

    if (isRoot) {
      if (!collapsedFolder.includes(id)) {
        setCollapsedFolder((prev) => [...prev, id]);
      }
    } else if (isFolder) {
      if (!collapsedFolder.includes(id)) {
        setCollapsedFolder((prev) => [...prev, id]);
      }
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id), id]);
    } else {
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id), id]);
    }

    const groups = node?.nodeGroups;

    if (groups) {
      groups.forEach((child) => {
        handleCollapse(child, false);
      });
    }
  };

  const handleUncollapse = (
    id: string,
    isCollapsed?: boolean,
    isRoot = true,
    shouldUncollapse = true,
    defaultToUncollapse = 5
  ): void => {
    const node = arrayTree.find((n) => n.id === id);
    const isFolder = operatorName[node?.name as 'RELATIONSHIP_MATCH' | 'OR' | 'AND'];

    if (isRoot) {
      setCollapsedFolder((prev) => [...prev.filter((item) => item !== id)]);
    } else {
      setCollapsedItems((prev) => [...prev.filter((item) => item !== id)]);
    }

    const groups = node?.nodeGroups;

    if (isRoot) {
      if (groups) {
        groups.forEach((child) => {
          handleUncollapse(
            child,
            true,
            false,
            isCollapsed ? true : groups.length < defaultToUncollapse
          );
        });
      }
    } else if (groups && shouldUncollapse) {
      if (isFolder) {
        setCollapsedFolder((prev) => [...prev.filter((item) => item !== id)]);
      }
      groups.forEach((child) => {
        handleUncollapse(
          child,
          true,
          false,
          isCollapsed ? true : groups.length < defaultToUncollapse
        );
      });
    }
  };

  useEffect(() => {
    if (arrayTree[0] && !firstCollapse) {
      setFirstCollapse(true);
      handleCollapse(arrayTree[0].id);
      handleUncollapse(arrayTree[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggeredNodes.length && showHighlights && isDefaultView) {
      triggeredNodesToUncollapse.forEach((id) => handleUncollapse(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDefaultView]);

  const handleChangeAnnotators = (nodeUUIDS: string[]): void => {
    let elements: string[] = [];
    const folders: string[] = [];
    nodeUUIDS.forEach((c) => {
      const current = arrayTree.find((n) => n.id === c);
      if (current) {
        elements.push(current.id);
        elements = [...elements, ...(current.nodeGroups ?? [])];
        folders.push(current.id);
      }
    });
    setCollapsedItems((prev) => prev.filter((item) => !elements.includes(item)));
    setCollapsedFolder((prev) => prev.filter((item) => !folders.includes(item)));
    prevUncollapsedAnnotators.current = [...prevUncollapsedAnnotators.current, ...elements];
  };

  useEffect(() => {
    prevUncollapsedAnnotators.current = [];
    selectedAnnotators.forEach((annotatorId) => {
      const nodes = arrayTree.filter((n) => n.annotatorId === annotatorId);
      nodes.forEach((node) => {
        if (node) {
          const nodeRoot = arrayTree.find((n) => n.level === 0)?.id;
          const parentNodes = node.parent.filter((np) => np !== nodeRoot);
          handleChangeAnnotators(parentNodes);
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotators.length]);

  const handleMove = async (fromId: string, targetIndex: number): Promise<void> => {
    const newParentId = arrayTree && arrayTree[targetIndex].id;
    const res = await dispatch(validateAndMove({ idToMove: fromId, newParentId }));
    // @ts-ignore
    if (res) {
      setIdToMove(null);
      setShowTransition(true);
    } else {
      setShowTransition(false);
      dispatch(showErrorAlert('Invalid move!'));
    }
  };

  const renderRowActionItem = (rowInd: number, node: MRuleConfig): JSX.Element => {
    const { name, id, parent } = node;
    const selectedItem = arrayTree[selectedNode as number]
      ? arrayTree[selectedNode as number]
      : false;
    const emptyButton = (
      <button type="button" className="pr-10 mr-3">
        {'\u00A0'}
      </button>
    );
    if (idToMove === id) return emptyButton;
    let actionItem = (
      <input
        id={`node-${rowInd}`}
        name="check"
        type="checkbox"
        checked={selectedNode === rowInd}
        onChange={(event): void => handleSelect(event)}
        data-testid={`checkbox-${rowInd}`}
        className="form-checkbox litlingo-checkbox mr-4 h-4 w-4 transition duration-150 ease-in-out"
        disabled={compareMode || inProd}
      />
    );
    // if in move mode show PUT button
    if (idToMove) {
      // only show put button if it's an and/or/same sentence
      // && the item to move isn't a parent descendent of current node
      // && if the selected item is a valid item to be moved to same sentence
      if (
        selectedItem !== false &&
        // @ts-ignore
        operatorName[name] &&
        !parent.includes(selectedItem.id) &&
        isSameSentenceApplicable(selectedItem, node)
      ) {
        actionItem = (
          <button
            type="button"
            className="button button--secondary font-bold px-2 h-6 mr-2 rounded"
            onClick={(): Promise<void> => handleMove(idToMove, rowInd)}
          >
            PUT
          </button>
        );
      } else {
        actionItem = emptyButton;
      }
    }
    return actionItem;
  };

  const renderRows = arrayTree.map((node, rowInd) => {
    const {
      name,
      description,
      id,
      level,
      relationship,
      modifiers,
      annotatorId,
      identifierId,
      deleted,
      negated,
      mode_of_speech,
      parent,
      color,
      change,
      changed,
      empty,
      original,
    } = node;

    if (collapsedItems.includes(id)) return null;

    const actionItem = renderRowActionItem(rowInd, node);
    const data = {
      id,
      index: rowInd,
      actionItem,
      level,
      name,
      description,
      relationship,
      modifiers,
      deleted,
      annotatorId,
      identifierId,
      negated,
      mode_of_speech,
      parent,
      color,
      changed,
      change,
      original,
    };

    if (empty) {
      return <div key={id} className="h-6 w-full bg-litlingo-gray-0.5" />;
    }

    return (
      <MakeDraggable
        key={id}
        Component={RowItem}
        data={data}
        arrayTree={arrayTree}
        handleMove={handleMove}
        idToMove={idToMove}
        showTransition={showTransition}
        handleCollapse={handleCollapse}
        handleUncollapse={handleUncollapse}
        collapsedFolder={collapsedFolder}
        collapsedItems={collapsedItems}
        disabled={compareMode}
      />
    );
  });

  return (
    <div data-testid="rule-manager-table" className="min-w-full w-full">
      <div>{arrayTree && renderRows}</div>
    </div>
  );
};

export default ModelManagerTable;
