/* eslint-disable max-lines */
import { Customer } from '@litlingo/client';
import { changeScope } from 'actions';
import CreateCategoryModal from 'components/Category/CreateCategoryModal';
import LoadingIndicator from 'components/LoadingIndicator';
import { CLOSE_ICON_12 } from 'constants/commonIcons';
import { SEARCH_ICON } from 'constants/filterIcons';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomersMeLoading } from 'selectors/auth';
import { getCategoriesList } from 'selectors/categories';
import { getSelectedNode, getSelectedNodeScopesForUser } from 'selectors/config';
import {
  getConfigRuleAsArray,
  getFetchRuleCustomersLoading,
  getRuleCustomers,
} from 'selectors/ruleGroup';
import { getSelectedRuleRevision } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import CustomerItem from './CustomerItem';
import SearchCustomerItem from './SearchCustomerItem';

const ScopesSection: React.FC = () => {
  const dispatch = useDispatch();

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [searchCustomers, setSearchCustomers] = useState(false);
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState<Customer[]>([]);
  const [body, setBody] = useState<string>('');

  const customers = useSelector(getRuleCustomers);
  const arrayTree = useSelector(getConfigRuleAsArray);
  const scopedCustomers = useSelector(getSelectedNodeScopesForUser);
  const selectedNodeIndex = useSelector(getSelectedNode);
  const ruleRevision = useSelector(getSelectedRuleRevision);
  const categories = useSelector(getCategoriesList);
  const ruleCustomersLoading = useSelector(getFetchRuleCustomersLoading);
  const customersMeLoading = useSelector(getCustomersMeLoading);
  const loading = ruleCustomersLoading || customersMeLoading;

  const sortedCustomers = [...customers];

  const filterBySearch = useCallback((): void => {
    setFiltered(
      customers.filter((item) => {
        const { name } = item;
        if (name != null) return name.toLowerCase().includes(search.toLowerCase() || '');
        return '';
      })
    );
  }, [customers, search]);

  useEffect(() => {
    filterBySearch();
  }, [search, filterBySearch, customers]);

  const toggleCreateCategoryModal = (): void => {
    setIsCreatingCategory(!isCreatingCategory);
  };

  const handleAddScopeCustomer = (customer: string): void => {
    if (selectedNodeIndex || selectedNodeIndex === 0) {
      const node = arrayTree[selectedNodeIndex];
      dispatch(changeScope({ act: 'add', customerId: customer, nodeId: node.id }));
      /* dispatch(selectItem({ index: null })); */
    }
  };

  const handleRemoveScopeCustomer = (customer: string): void => {
    if (selectedNodeIndex || selectedNodeIndex === 0) {
      const node = arrayTree[selectedNodeIndex];
      dispatch(changeScope({ act: 'remove', customerId: customer, nodeId: node.id }));
    }
  };

  const handleSearchChange = (value: string): void => {
    setSearch(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(
    debounce((eventValue) => {
      handleSearchChange(eventValue);
    }, 500),
    []
  );

  const sortCustomers = (a: Customer, b: Customer): number => {
    if (scopedCustomers.includes(a.uuid) && !scopedCustomers.includes(b.uuid)) {
      return 1;
    }
    if (!scopedCustomers.includes(a.uuid) && scopedCustomers.includes(b.uuid)) {
      return -1;
    }
    if (a.name && b.name) {
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }
    return 0;
  };

  return (
    <>
      <div
        className="flex flex-col overflow-y-scroll overflow-x-hidden no-scrollbar"
        style={{ height: '50vh' }}
      >
        <div className="sticky top-0 bg-white z-10">
          {searchCustomers ? (
            <div className=" flex flex-row px-4 py-2 border-b border-litlingo-gray-1 w-full transition ease-in-out duration-150">
              <div className="flex flex-row w-full">
                <input
                  id="search"
                  name="search"
                  className="border-l border-t border-b border-litlingo-gray-2 h-8 w-full text-litlingo-gray-6 pl-4 text-body placeholder-italic placeholder-litlingo-gray-6 rounded-l"
                  placeholder="Search workspaces"
                  onChange={(event): void => {
                    debouncedChange(event.target.value);
                    setBody(event.target.value);
                  }}
                  value={body}
                  autoComplete="off"
                />
                <button
                  type="button"
                  className="focus:outline-none border-t border-b border-r border-litlingo-gray-2 pr-2"
                  onClick={(): void => setSearchCustomers(false)}
                >
                  {CLOSE_ICON_12}
                </button>
              </div>
              <div className="flex items-center px-1 rounded-r border-t border-b border-r border-litlingo-gray-2 w-7 ">
                <button
                  type="button"
                  className="focus:outline-none w-5"
                  onClick={(): void => {
                    setSearchCustomers(true);
                  }}
                >
                  {SEARCH_ICON()}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-between items-center border-b border-litlingo-gray-1 px-4 py-2">
              <div className="text-litlingo-gray-6">
                {(selectedNodeIndex && selectedNodeIndex !== -1) || selectedNodeIndex === 0
                  ? `${customers.length - scopedCustomers.length} exclude workspaces`
                  : `${customers.length} workspaces`}
              </div>
              <div className="flex flex-row gap-2">
                <button
                  type="button"
                  className="button button--secondary self-end h-8"
                  onClick={(e): void => {
                    e.stopPropagation();
                    toggleCreateCategoryModal();
                  }}
                >
                  <span className="font-bold">Add</span>
                </button>
                <div className="flex items-center px-1 rounded border border-litlingo-gray-3 w-7 ">
                  <button
                    type="button"
                    className="focus:outline-none w-5"
                    onClick={(): void => {
                      setSearchCustomers(true);
                    }}
                  >
                    {SEARCH_ICON()}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col">
          {loading ? (
            <div className="flex justify-center py-2">
              <LoadingIndicator size="10" />
            </div>
          ) : (
            <div>
              {searchCustomers
                ? filtered
                    .sort(sortCustomers)
                    .map((customer) => (
                      <SearchCustomerItem key={customer.uuid} customer={customer} search={search} />
                    ))
                : sortedCustomers
                    .sort(sortCustomers)
                    .map((customer) => (
                      <CustomerItem
                        key={customer.uuid}
                        customer={customer}
                        handleAddScopeCustomer={handleAddScopeCustomer}
                        handleRemoveScopeCustomer={handleRemoveScopeCustomer}
                      />
                    ))}
            </div>
          )}
        </div>
      </div>
      {isCreatingCategory && (
        <CreateCategoryModal
          toggleModal={toggleCreateCategoryModal}
          modelId={categories && categories[0] ? categories[0].model_uuid : ''}
          ruleId={ruleRevision?.rule_group_uuid}
          fromRule
          redirect=""
        />
      )}
    </>
  );
};

export default ScopesSection;
