/* eslint-disable max-lines */
import {
  addValueToTree,
  continueAssignment,
  exportEnvelopesSearch,
  makeSearchOnSandbox,
  setModelMetricType,
  setReviewOnSkipView,
  setShowSkippedEnvelopes,
  showErrorAlert,
  upsertSavedSearchParams,
} from 'actions';
import {
  openExploreMessagesModal,
  openExploreSubsamplesModal,
  reviewSetCreated,
} from 'actions/envelopeListView';
import ButtonMenu from 'components/Button/ButtonMenu';
import SelectCustomerModal from 'components/CustomerList/SelectCustomerModal';
import AdvancedSearchModal from 'components/Filters/AdvancedSearchModal';
import ExploreFilter from 'components/Filters/ExploreFilter';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import AdditionalDetailsModal from 'components/ReviewSets/AdditionalDetailsModal';
import CreateReviewSetModal from 'components/ReviewSets/CreateReviewSetModal';
import FirstTimeCreateReviewSetModal from 'components/ReviewSets/FirstTimeCreateReviewSetModal';
import CreateSampleModal from 'components/Sample/CreateSampleModal';
import SubsamplesModal from 'components/Sample/SubsamplesModal';
import SaveFiltersModal from 'components/SaveFiltersModal';
import EnvelopeListSearchInput from 'components/SearchInput/EnvelopeListSearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getShowSkippedEnvelopes, getUsersSingleAssigment } from 'selectors/assignments';
import {
  getCustomerDomain,
  getCustomerId,
  getCustomersAllowFulTextSearch,
  getLowDataMode,
} from 'selectors/auth';
import {
  envelopeListContentState,
  getExploreMessagesModalOpen,
  getExploreSubsamplesModalOpen,
  getIsReviewSetCreated,
} from 'selectors/envelopeListView';
import { getReviewOnSkipView } from 'selectors/envelopeReview';
import { getAllFilterValuesFromTreeWithLabels } from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { getSelectedReviewSet, getUpsertSavedSearchParamsLoading } from 'selectors/savedSearches';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';
import EnvelopeListCount from './EnvelopeListCount';
import ExpandContractButtons from './ExpandContractButtons';
import EnvelopeListFilterPills from './FilterPills';
import MessagesModal from './FilterPills/MessagesModal';

type ComponentProps = {
  scrolled: boolean;
  selectedFormat: 'expanded' | 'contracted';
  setSelectedFormat: React.Dispatch<React.SetStateAction<'expanded' | 'contracted'>>;
};

const EnvelopeListContentHeader: React.FC<ComponentProps> = ({
  scrolled,
  selectedFormat,
  setSelectedFormat,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const domain = useSelector(getCustomerDomain);
  const customerId = useSelector(getCustomerId);
  const urlTreeParams = useSelector(getAllFilterValuesFromTreeWithLabels);

  const { loadingAll, totalCount, comingFromAssignments } = useSelector(envelopeListContentState);

  const isReviewSetCreated = useSelector(getIsReviewSetCreated);

  const currentReviewSet = useSelector(getSelectedReviewSet);

  const editReviewSetParamsLoading = useSelector(getUpsertSavedSearchParamsLoading);

  const showSkippedEnvelopes = useSelector(getShowSkippedEnvelopes);
  const assignment = useSelector(getUsersSingleAssigment);
  const revieOnSkipView = useSelector(getReviewOnSkipView);
  const isSubsamplesModalOpen = useSelector(getExploreSubsamplesModalOpen);
  const isMessagesModalOpen = useSelector(getExploreMessagesModalOpen);
  const lowDataMode = useSelector(getLowDataMode);
  const showSearchBar = useSelector(getCustomersAllowFulTextSearch);

  const [showAllFilters, setShowAllFilters] = useState(true);
  const [filtersModified, setFiltersModified] = useState(false);

  const [isSavedFilterModalOpen, setIsSavedFilterModalOpen] = useState(false);
  const [isCreateSampleModalOpen, setIsCreateSampleModalOpen] = useState(false);
  const [isCreateReviewSetModalOpen, setIsCreateReviewSetModalOpen] = useState(false);
  const [isFirstTimeCreateReviewSetModalOpen, setIsFirstTimeCreateReviewSetModalOpen] =
    useState(false);
  const [isAdditionalDetailsModalOpen, setIsAdditionalDetailsModalOpen] = useState(false);
  const [showSandboxModal, setShowSandboxModal] = useState(false);
  const [isAdvancedSearchModalOpen, setIsAdvancedSearchModalOpen] = useState(false);

  const createPerformanceMetrics = navParams.performance_type;

  const handleSelectCustomerToApplySearch = (customer: {
    domain: string | null;
    uuid: string;
    label: string | null;
  }): void => {
    if (!customerId) return;
    if (customerId === customer.uuid) return;
    dispatch(makeSearchOnSandbox({ sabdboxUUID: customer.uuid }));
    setShowSandboxModal(false);
  };

  const handleShowSandBoxModal = (): void => setShowSandboxModal(true);

  useEffect(() => {
    setFiltersModified(true);
  }, [navParams]);

  useEffect(() => {
    if (isReviewSetCreated && !isFirstTimeCreateReviewSetModalOpen) {
      setIsAdditionalDetailsModalOpen(true);
    }
  }, [isReviewSetCreated, isFirstTimeCreateReviewSetModalOpen]);

  const isReviewSetEdit = useMemo(() => !!navParams.review_set_edit_uuid, [navParams]);

  const toggleFirstTimeCreateReviewSetModalOpen = (): void =>
    setIsFirstTimeCreateReviewSetModalOpen(!isFirstTimeCreateReviewSetModalOpen);

  useEffect(() => {
    if (navParams.is_first_time_create_review_set) {
      setIsFirstTimeCreateReviewSetModalOpen(true);
    }
  }, [navParams]);

  const handleReviewSetCreated = (): void => history.replace(`review-set-manager`);

  const handleSaveFiltersClick = (): void => {
    logEvent('envelopes-list-save-search-click');
    if (location.search === '') {
      dispatch(
        showErrorAlert('You have not applied any filters yet, please add filters and try again')
      );
    } else {
      setIsSavedFilterModalOpen(true);
    }
  };

  const handleCreateSampleClick = (): void => {
    logEvent('envelopes-list-create-sample-click');
    setIsCreateSampleModalOpen(true);
  };

  const handleCreateReviewSetClick = (): void => {
    logEvent('envelopes-list-create-review-set-click');
    dispatch(
      addValueToTree({
        field: 'review_values',
        value: 'pending',
        label: 'Pending',
        isSingleValue: true,
      })
    );

    setIsCreateReviewSetModalOpen(true);
  };

  const handleEditReviewSetClick = (): void => {
    logEvent('envelopes-list-edit-review-set-click');
    const urlParams = new URLSearchParams();

    let stripedParams = Object.entries(navParams);

    if (navParams.filters_search) {
      stripedParams = stripedParams.filter(([key]) => ['filters_search', 'matches'].includes(key));
    }

    stripedParams.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (key === 'matches' || key === 'has_events') {
          const matches = value.reduce((acc, curr) => {
            if (!acc.includes(curr)) {
              acc.push(curr);
            }
            return acc;
          }, [] as string[]);
          matches.forEach((v) => {
            urlParams.append('envelopes__has_events', v);
          });
        }
        if (key !== 'has_events' && key !== 'matches') {
          value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
        }
      } else {
        urlParams.append(`envelopes__${key}`, value);
      }
    });

    urlParams.delete('envelopes__review_set_edit_uuid');
    const reviewSetUuid = (navParams.review_set_edit_uuid as string).split('-saved');
    const urlQuery = `?${urlParams.toString()}`;
    dispatch(
      upsertSavedSearchParams({
        uuid: reviewSetUuid[0],
        url: urlQuery,
        redirect: reviewSetUuid.length > 1 ? 'saved-searches' : 'review-set-manager',
      })
    );
  };

  const handleCreatePerformanceType = (): void => {
    const urlParams = new URLSearchParams();

    let stripedParams = Object.entries(navParams);

    if (navParams.filters_search) {
      stripedParams = stripedParams.filter(([key]) => ['filters_search', 'matches'].includes(key));
    }

    stripedParams.forEach(([key, value]) => {
      if (Array.isArray(value)) {
        if (key === 'matches' || key === 'has_events') {
          const matches = value.reduce((acc, curr) => {
            if (!acc.includes(curr)) {
              acc.push(curr);
            }
            return acc;
          }, [] as string[]);
          matches.forEach((v) => {
            urlParams.append('envelopes__has_events', v);
          });
        }
        if (key !== 'has_events' && key !== 'matches') {
          value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
        }
      } else {
        urlParams.append(`envelopes__${key}`, value);
      }
    });

    urlParams.delete('envelopes__performance_type');

    const urlValue = urlParams.get('envelopes__filters_search')?.toString() as string;

    dispatch(
      setModelMetricType({
        type: createPerformanceMetrics as string,
        value: urlValue,
      })
    );
    history.pushLookup({ routeName: 'create-models-metrics' });
  };

  const handleEditReviewSetCancelClick = (): void => {
    logEvent('envelopes-list-edit-review-set-cancel-click');
    const reviewSetUuid = (navParams.review_set_edit_uuid as string).split('-saved');
    if (reviewSetUuid.length > 1) {
      history.pushLookup({ routeName: 'saved-searches', customerDomain: domain });
    } else {
      history.pushLookup({ routeName: 'review-set-manager', customerDomain: domain });
    }
  };

  const handleExportSearchClick = (): void => {
    dispatch(exportEnvelopesSearch);
  };

  const toggleSavedFilterModalOpen = (): void => {
    setIsSavedFilterModalOpen(!isSavedFilterModalOpen);
  };

  const toggleCreateSampleModalOpen = (): void => {
    setIsCreateSampleModalOpen(!isCreateSampleModalOpen);
  };

  const toggleCreateReviewSetModalOpen = (): void => {
    setIsCreateReviewSetModalOpen(!isCreateReviewSetModalOpen);
  };

  const toggleAdditionalDetailsModalOpen = (): void => {
    if (isAdditionalDetailsModalOpen) {
      setIsAdditionalDetailsModalOpen(false);
      dispatch(reviewSetCreated(false));
    }
    setIsAdditionalDetailsModalOpen(!isAdditionalDetailsModalOpen);
  };

  const toggleSubsamplesModal = (): void => {
    dispatch(openExploreSubsamplesModal());
  };
  const toggleMessagesModal = (): void => {
    dispatch(openExploreMessagesModal());
  };

  const toggleAdvancedSearchModalOpen = (): void => {
    setIsAdvancedSearchModalOpen(!isAdvancedSearchModalOpen);
  };

  const handleContinueAssignment = (): void => {
    dispatch(setShowSkippedEnvelopes(false));
    if (assignment) {
      dispatch(
        continueAssignment({
          uuid: assignment.uuid,
          skip_if_unreviewed: revieOnSkipView,
        })
      );
      dispatch(setReviewOnSkipView(false));
    }
  };

  const handleGoToAssignments = (): void => {
    dispatch(setShowSkippedEnvelopes(false));
  };

  const handleAdvancedSearchClick = (): void => {
    logEvent('envelopes-list-advanced-search-click');
    setIsAdvancedSearchModalOpen(true);
  };

  const countBypass = ['broad_search', 'subject'];
  const filtersCount = Object.entries(urlTreeParams).reduce(
    (acc, [key, val]) => (countBypass.includes(key) ? acc + 1 : acc + val.length),
    0
  );

  // @ts-ignore
  const canSaveSample = currentReviewSet?.category !== 'sample';

  const options = [
    {
      id: 'save-search',
      label: 'Save Search',
      action: handleSaveFiltersClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'review-stream',
      label: 'Create Review',
      action: handleCreateReviewSetClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'create-sample',
      label: 'Quick Sample',
      action: handleCreateSampleClick,
      permissions: 'communications.reprocess',
    },
    {
      id: 'export-search',
      label: 'Export Metadata',
      action: handleExportSearchClick,
      permissions: 'communications.export',
    },
    {
      id: 'sandbox-search',
      label: 'Go to clone',
      action: handleShowSandBoxModal,
      permissions: 'communications.sandbox',
    },
  ];

  return (
    <>
      <div>
        {showSkippedEnvelopes ? (
          <div className="flex flex-row justify-between px-2 mb-3">
            <div className="flex flex-row gap-1 items-center">
              <LinkLookup
                type="button"
                routeName="review-set"
                className="text-litlingo-gray-6 focus:outline-none"
                onClick={handleGoToAssignments}
              >
                Assignment
              </LinkLookup>
              <div>{`>`}</div>
              <button
                type="button"
                className="text-litlingo-gray-6 focus:outline-none"
                onClick={handleContinueAssignment}
              >
                <span> {assignment?.saved_search?.name}</span>
              </button>
            </div>
            <button
              type="button"
              className="button button--secondary h-8"
              onClick={handleContinueAssignment}
            >
              <span className="text-litlingo-gray-6 font-bold">Back to Assignment</span>
            </button>
          </div>
        ) : (
          <div
            id="envelope-header-div-1"
            className={`flex flex-row justify-between items-center px-6 mb-4 title transition-all duration-500 overflow-visible h-8 text-xl leading-6 ${
              scrolled ? 'h-8 text-xl leading-6' : 'h-10 text-3.5xl'
            }`}
          >
            <div className="flex flex-row items-center gap-x-2">
              <span>{comingFromAssignments ? comingFromAssignments.reviewSetName : 'Explore'}</span>
              <ExploreFilter resource={resourceQueryParamName.envelopes} loading={loadingAll} />
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Permissions action="events.broadSearch">
                {showSearchBar && !lowDataMode && (
                  <EnvelopeListSearchInput
                    field="broad_search"
                    className="min-w-78"
                    logEventMessage="events-filters-search"
                  />
                )}
              </Permissions>
              {!lowDataMode && (
                <Permissions action="communication_envelopes.filter_search_advanced">
                  <div className="flex flex-row justify-center items-center w-full">
                    <button
                      type="button"
                      className="flex flex-row justify-center items-center gap-1 text-small focus:outline-none"
                      onClick={handleAdvancedSearchClick}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.98187 1.2002H1.19995V22.8002H22.5337V14.4561"
                          stroke="#333333"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M22.5336 9.24842V1.2002H14.3966"
                          stroke="#333333"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.8519 12.0002L22.5336 1.2002"
                          stroke="#333333"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </Permissions>
              )}
            </div>
          </div>
        )}

        {showSkippedEnvelopes ? (
          <div className="flex flex-row justify-between px-2 mb-4">
            <div className="text-xl font-bold h-6 focus:outline-none">Skipped Messages</div>
            <div className="flex flex-row gap-3 self-end">
              <ExpandContractButtons
                selectedFormat={selectedFormat}
                setSelectedFormat={setSelectedFormat}
              />
              <EnvelopeListCount />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 px-6 pb-2">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center gap-1">
                <button
                  type="button"
                  data-testid="expand-filter-pills-button"
                  className="flex flex-row gap-1 items-center focus:outline-none"
                  onClick={(): void => setShowAllFilters((b) => !b)}
                >
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transform ${
                      showAllFilters ? 'rotate-90' : ''
                    } transition-all duration-300`}
                  >
                    <path
                      d="M2.08961 9.91604C2.1425 9.96937 2.21437 9.99955 2.28948 9.99999C2.32675 10.0003 2.3637 9.99307 2.39807 9.97864C2.43244 9.9642 2.4635 9.9429 2.48934 9.91604L7.20219 5.20319C7.22866 5.17707 7.24968 5.14595 7.26402 5.11164C7.27837 5.07733 7.28575 5.04051 7.28575 5.00333C7.28575 4.96614 7.27837 4.92932 7.26402 4.89501C7.24968 4.8607 7.22866 4.82958 7.20219 4.80346L2.48934 0.0896109C2.46343 0.0619091 2.43221 0.0397035 2.39754 0.0243155C2.36287 0.00892748 2.32545 0.000671647 2.28753 3.93014e-05C2.2496 -0.000593044 2.21193 0.00641102 2.17677 0.0206347C2.1416 0.0348583 2.10966 0.0560109 2.08283 0.0828334C2.05601 0.109656 2.03486 0.1416 2.02063 0.176766C2.00641 0.211931 1.99941 0.249598 2.00004 0.287526C2.00067 0.325453 2.00893 0.362866 2.02432 0.397537C2.0397 0.432209 2.06191 0.46343 2.08961 0.489344L6.6026 5.00333L2.08961 9.51631C2.06326 9.5425 2.04235 9.57364 2.02809 9.60794C2.01382 9.64224 2.00647 9.67903 2.00647 9.71618C2.00647 9.75333 2.01382 9.79011 2.02809 9.82441C2.04235 9.85871 2.06326 9.88985 2.08961 9.91604Z"
                      fill="#333333"
                    />
                  </svg>

                  <span>Filtering by</span>
                </button>
                <span className="w-4.5 h-4.5 flex justify-center items-center rounded-full bg-litlingo-primary-60">
                  {filtersCount}
                </span>
              </div>

              <div className="flex flex-row gap-3 self-end">
                {!lowDataMode && (
                  <ExpandContractButtons
                    selectedFormat={selectedFormat}
                    setSelectedFormat={setSelectedFormat}
                  />
                )}
                <EnvelopeListCount />
              </div>
            </div>
            {showAllFilters && (
              <div className="flex flex-row">
                <div
                  data-testid="pills-section"
                  id="pills-section"
                  className="flex flex-row flex-1 items-baseline justify-between transition-all duration-500 overflow-visible"
                >
                  <div className="flex flex-1 flex-row flex-start items-baseline text-xss font-base text-litlingo-gray-6 whitespace-no-wrap">
                    <EnvelopeListFilterPills
                      showAllFilters={showAllFilters}
                      filtersModified={filtersModified}
                    />
                  </div>
                </div>
                {createPerformanceMetrics ? (
                  <button
                    type="button"
                    className="button button--primary"
                    onClick={handleCreatePerformanceType}
                  >
                    Save
                  </button>
                ) : (
                  <div className="w-22.5">
                    <Permissions action="communication_envelopes.filter_misc_actions">
                      <ButtonMenu
                        isReviewSetEdit={isReviewSetEdit}
                        canSaveSample={canSaveSample}
                        handleCancelReviewSet={handleEditReviewSetCancelClick}
                        handleEditReviewSet={handleEditReviewSetClick}
                        handleSaveNewSample={handleCreateSampleClick}
                        primaryLabel="Actions"
                        options={options}
                        loading={editReviewSetParamsLoading}
                      />
                    </Permissions>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {isSavedFilterModalOpen && (
        <SaveFiltersModal
          isModalOpen={isSavedFilterModalOpen}
          toggleModalOpen={toggleSavedFilterModalOpen}
        />
      )}
      {isCreateSampleModalOpen && (
        <CreateSampleModal
          isModalOpen={isCreateSampleModalOpen}
          toggleModalOpen={toggleCreateSampleModalOpen}
          envelopesTotalCount={totalCount}
        />
      )}
      {isCreateReviewSetModalOpen && (
        <CreateReviewSetModal
          isModalOpen={isCreateReviewSetModalOpen}
          toggleModalOpen={toggleCreateReviewSetModalOpen}
        />
      )}
      {isFirstTimeCreateReviewSetModalOpen && (
        <FirstTimeCreateReviewSetModal
          isModalOpen={isFirstTimeCreateReviewSetModalOpen}
          toggleModalOpen={toggleFirstTimeCreateReviewSetModalOpen}
          navigateToReviewManagement={handleReviewSetCreated}
        />
      )}
      {isAdditionalDetailsModalOpen && (
        <AdditionalDetailsModal
          reviewSet={currentReviewSet}
          isModalOpen={isAdditionalDetailsModalOpen}
          toggleModalOpen={toggleAdditionalDetailsModalOpen}
        />
      )}
      {showSandboxModal && (
        <SelectCustomerModal
          showActionsButton={false}
          selectCustomer={handleSelectCustomerToApplySearch}
          toggleModalOpen={(): void => setShowSandboxModal(false)}
          onlyCloneCustomers
        />
      )}
      {isSubsamplesModalOpen && (
        <SubsamplesModal
          isModalOpen={isSubsamplesModalOpen}
          toggleModalOpen={toggleSubsamplesModal}
          modifyExploreTree
          treeParams={{}}
        />
      )}
      {isMessagesModalOpen && (
        <MessagesModal
          isModalOpen={isMessagesModalOpen}
          toggleModalOpen={toggleMessagesModal}
          modifyExploreTree
          treeParams={{}}
        />
      )}
      {isAdvancedSearchModalOpen && (
        <AdvancedSearchModal
          isModalOpen={isAdvancedSearchModalOpen}
          toggleModalOpen={toggleAdvancedSearchModalOpen}
        />
      )}
    </>
  );
};

export default EnvelopeListContentHeader;
