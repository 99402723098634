/* eslint-disable camelcase */
/* eslint-disable max-lines */

import React, { useState } from 'react';
import { MModelMetric } from 'types';
import { Tree } from 'utils/parserTree';
import EnvelopeListFilterPills from './FilterPills';
import { ModelMetricsType } from './ModelMetricsCardsSection';

type ComponentProps = {
  aggs:
    | {
        [key: string]: {
          [key: string]: {
            count: {
              buckets: { key: string; doc_count: number }[];
            };
            doc_count: number;
          };
        };
      }
    | undefined;
  exp: MModelMetric;
  modelMetrics: ModelMetricsType;
  selectedModels: string[];
  setSelectedModels: React.Dispatch<React.SetStateAction<string[]>>;
  compareMode: 'left' | 'right' | '';
  corpusTree: Tree;
  positiveTree: Tree;
  negativeTree: Tree;
};

const ModelMetricsCard: React.FC<ComponentProps> = ({
  aggs,
  exp,
  modelMetrics,
  selectedModels,
  setSelectedModels,
  compareMode,
  corpusTree,
  positiveTree,
  negativeTree,
}) => {
  const [openDetails, setOpenDetails] = useState(false);

  const handleSelectModel = (modelId: string): void => {
    if (selectedModels.includes(modelId)) {
      setSelectedModels((s) => s.filter((m) => m !== modelId));
    } else {
      setSelectedModels((s) => [...s, modelId]);
    }
  };

  const handleToggleDetails = (): void => {
    setOpenDetails((d) => !d);
  };

  return (
    <div className="flex flex-col">
      <div
        className="pt-4 pr-6 pb-4 pl-4 flex flex-row justify-between bg-white border border-litlingo-gray-2 rounded z-10"
        style={{
          ...(selectedModels.includes(modelMetrics.uuid)
            ? {
                boxShadow: '3px 1px 8px 0px rgba(0, 0, 0, 0.50)',
              }
            : {}),
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <input
                type="checkbox"
                data-testid="model-metrics-card-checkbox"
                className="w-4 h-4 form-checkbox litlingo-checkbox"
                onChange={(): void => handleSelectModel(modelMetrics.uuid)}
                checked={selectedModels.includes(modelMetrics.uuid)}
                disabled={!!compareMode}
              />
              <span className="text-heading-3">{modelMetrics.name}</span>
            </div>
            <div className="mt-1 border border-litlingo-gray-2 rounded flex flex-row items-center">
              <div className="p-2 h-full flex flex-row gap-2">
                <div className="flex flex-col w-20 justify-center items-center gap-1">
                  <span className="text-heading-2">
                    {(modelMetrics.precision * 100).toFixed(1)}%
                  </span>
                  <span className="text-small">Precision</span>
                </div>
                <div className="h-full border-r border-litlingo-gray-2" />
                <div className="flex flex-col w-20 justify-center items-center gap-1">
                  <span className="text-heading-2">{(modelMetrics.recall * 100).toFixed(0)}%</span>
                  <span className="text-small">Recall*</span>
                </div>
                <div className="h-full border-r border-litlingo-gray-2" />
                <div className="flex flex-col w-18 justify-center items-center gap-1">
                  <span className="text-heading-2">.{(modelMetrics.f1 * 100).toFixed(0)}</span>
                  <span className="text-small">F1</span>
                </div>
                <div className="h-full border-r border-litlingo-gray-2" />
                <div className="flex flex-col w-18 justify-center items-center gap-1">
                  <span className="text-heading-2">.{(modelMetrics.f2 * 100).toFixed(0)}</span>
                  <span className="text-small">F2</span>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="flex flex-row items-center gap-1 focus:outline-none"
                onClick={handleToggleDetails}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
                    fill="currentColor"
                  />
                </svg>
                <span>More details</span>
              </button>
            </div>
          </div>
        </div>
        <div className="pt-2 flex flex-col gap-2 text-body self-end">
          <div className="flex flex-row items-end self-end justify-between font-bold">
            <div className="mr-2.5 pb-1.5 flex flex-col gap-3 justify-end items-end">
              <span className="">Match</span>
              <span className="whitespace-no-wrap truncate">No match</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="mb-0.5">Model</span>
              <div className="h-8 w-20 px-2.5 py-1.5 items-center text-center border-l border-t border-litlingo-gray-3 rounded-tl focus:outline-none">
                {modelMetrics.true_positive}
              </div>
              <div className="h-8 w-20 px-2.5 py-1.5 items-center text-center border-l border-b border-t border-litlingo-gray-3 rounded-bl focus:outline-none">
                {modelMetrics.true_negative}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <span className="mb-0.5">Reviewer</span>
              <div className="h-8 w-20 px-2.5 py-1.5 items-center text-center border-r border-l border-t border-litlingo-gray-3 rounded-tr focus:outline-none">
                {modelMetrics.false_negative}
              </div>
              <div className="h-8 w-20 px-2.5 py-1.5 items-center text-center border border-litlingo-gray-3 rounded-br focus:outline-none">
                {modelMetrics.false_positive}
              </div>
            </div>
          </div>
          <span className="self-end">
            Corpus:{' '}
            {aggs && aggs[exp.id]
              ? aggs[exp.id].true_positive.doc_count + aggs[exp.id].false_positive.doc_count
              : null}
          </span>
        </div>
      </div>
      {(openDetails || compareMode) && (
        <div className="-mt-1 border border-litlingo-gray-2">
          <div className="pt-4 pb-2 pl-10 bg-litlingo-gray-0.5 border-b border-litlingo-gray-2">
            <span className="font-bold">Sample Composition</span>
          </div>
          <div
            id="pills-section"
            className="px-10 py-4 flex flex-col justify-between gap-3 text-body"
          >
            <div className="flex flex-col gap-1 whitespace-no-wrap">
              <span>
                Corpus:{' '}
                {aggs && aggs[exp.id]
                  ? aggs[exp.id].true_positive.doc_count + aggs[exp.id].false_positive.doc_count
                  : null}
              </span>
              <EnvelopeListFilterPills customTree={corpusTree} />
            </div>

            <div className="flex flex-col gap-1 whitespace-no-wrap">
              <span>
                Positive: {aggs && aggs[exp.id] ? aggs[exp.id].true_positive.doc_count : null}
              </span>
              <EnvelopeListFilterPills customTree={positiveTree} />
            </div>

            <div className="flex flex-col gap-1 whitespace-no-wrap">
              <span>
                Negative: {aggs && aggs[exp.id] ? aggs[exp.id].false_positive.doc_count : null}
              </span>
              <EnvelopeListFilterPills customTree={negativeTree} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelMetricsCard;
