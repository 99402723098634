/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { INDENTATION_ICON } from 'constants/commonIcons';
import React, { useEffect, useState } from 'react';
import { getSurroundingContextRulesIds } from 'selectors/auth';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';
import logEvent from 'utils/analytics';
import sortCampaigns, { CampaignWithRules } from 'utils/campaigns';

type NormalizedCampaignWithRules = {
  [key: string]: CampaignWithRules;
};

type GetCampaignsRules = (
  events: CommunicationEnvelopeSummary['events']
) => NormalizedCampaignWithRules;

const getCampaignsRules: GetCampaignsRules = (events) => {
  if (!events) return {};

  const campaigns: NormalizedCampaignWithRules = {};

  events.forEach((e) => {
    if (e.campaign_uuid) {
      campaigns[e.campaign_uuid] = {
        campaignName: e.campaign_name,
        campaignPriority: e.campaign_priority || 0,
        rules: [],
      };
    }
  });

  events.forEach((e) => {
    if (e.campaign_uuid) {
      if (e.rule_uuid) {
        if (!campaigns[e.campaign_uuid].rules.some((r) => r.ruleId === e.rule_uuid)) {
          campaigns[e.campaign_uuid].rules.push({ ruleName: e.rule_name, ruleId: e.rule_uuid });
        }
      }
    }
  });

  return campaigns;
};

type ComponentProps = {
  events: CommunicationEnvelopeSummary['events'];
  selectedRule: string | null;
  setSelectedRule: React.Dispatch<React.SetStateAction<string | null>>;
  expanded: boolean;
};

const EnvelopeListCampaignsRules: React.FC<ComponentProps> = ({
  events,
  selectedRule,
  setSelectedRule,
  expanded,
}) => {
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);

  const contextRulesIds = useSelector(getSurroundingContextRulesIds);

  const campaignsRules = getCampaignsRules(
    events.filter((e) => !contextRulesIds.includes(e.rule_uuid || ''))
  );

  const hitContextRules = events.some((e) => contextRulesIds.includes(e.rule_uuid || ''));

  useEffect(() => {
    const campaigns = Object.entries(campaignsRules).sort(sortCampaigns);

    if (selectedRule === null && campaigns[0]) {
      const rules = [...campaigns[0][1].rules];
      rules.sort();

      setSelectedRule(rules[0].ruleId);
      setSelectedCampaign(campaigns[0][0]);
    }
  }, [campaignsRules, selectedRule, setSelectedRule]);

  const selectCampaign = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    e.stopPropagation();
    setSelectedCampaign(id);
    setSelectedRule(campaignsRules[id].rules[0].ruleId);
    logEvent('envelopes-list-select-rule');
  };

  const selectRule = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    campaignId: string
  ): void => {
    e.stopPropagation();
    setSelectedCampaign(campaignId);
    setSelectedRule(id);
    logEvent('envelopes-list-select-rule');
  };

  if (
    !events ||
    events.length === 0 ||
    (!Object.entries(campaignsRules).length && hitContextRules)
  ) {
    return <div className="text-body italic leading-5">No matches found</div>;
  }

  if (!Object.entries(campaignsRules).length) return null;

  return (
    <div>
      {Object.entries(campaignsRules)
        .sort(sortCampaigns)
        .map(([key, value]) => (
          <div className="flex flex-col mb-2" key={key}>
            <button
              type="button"
              className="flex flex-col focus:outline-none"
              onClick={(e): void => selectCampaign(e, key)}
            >
              <span
                className={`font-normal text-base ${
                  selectedCampaign !== key ? 'hover:underline' : ''
                }`}
              >
                {value.campaignName}
              </span>
            </button>
            {(selectedCampaign === key || expanded) && (
              <div>
                {value.rules.sort().map((r) => (
                  <div key={r.ruleId} className="flex flex-row gap-1 pl-2 mt-1">
                    <div>{INDENTATION_ICON}</div>
                    <button
                      type="button"
                      className="focus:outline-none"
                      onClick={(e): void => selectRule(e, r.ruleId, key)}
                    >
                      <span
                        className={`font-normal text-base ${
                          r.ruleId === selectedRule ? 'bg-litlingo-highlight' : 'hover:underline'
                        }`}
                      >
                        {r.ruleName}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default EnvelopeListCampaignsRules;
