/* eslint-disable camelcase */
import {
  annotateCommunicationGroups,
  annotateCommunicationGroupsLegacy,
  remapModesOfSpeech,
} from '@litlingo/react';
import * as Sentry from '@sentry/react';
import {
  createRuleGroupRequest,
  fetchRuleCustomersRequest,
  fetchSingleRuleGroupRequest,
  previewRuleGroupRequest,
  saveRuleRequest,
} from 'actions/ruleGroup';
import { isNumber, isObject } from 'lodash';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type {
  AnnotatorRelationship,
  Category,
  Customer,
  MRuleConfig,
  MRuleConfigNode,
  MRuleConfigRelationship,
  MRuleConfigWithChanges,
  MRuleGroup,
  MRuleRevision,
  ModeOfSpeech,
  NormalizedResource,
  OperandsValue,
  RuleChange,
  RuleIdentifier,
  RuleResults,
  Selector,
  TestCommunication,
  UUID,
} from 'types';
import { getUseNewGraphs } from './auth';

export const getRule =
  (ruleId: UUID): Selector<MRuleGroup> =>
  (state): MRuleGroup =>
    state.ruleGroups.rules[ruleId];

export const getRuleIdentifiers: Selector<RuleIdentifier[]> = (state) =>
  state.ruleGroups.selectedRule?.identifiers || [];

export const getCompareRuleIdentifiers: Selector<RuleIdentifier[]> = (state) =>
  state.config.compareRule?.identifiers || [];

export const getSelectedRuleIdentifiers: Selector<RuleIdentifier[]> = (state) => {
  const customerGroup = state.ruleGroups.selectedCustomerGroup;

  if (customerGroup === 'production') {
    return state.config.compareRule?.identifiers || [];
  }
  return state.ruleGroups.selectedRule?.identifiers || [];
};

export const getRuleConfigId: Selector<string | null> = (state) =>
  state.ruleGroups.selectedRule?.rootConfigId || null;

export const getConfigRuleAsArray: Selector<MRuleConfig[]> = createSelector(
  [
    (state): NormalizedResource<MRuleConfigNode> => state.config.items,
    (state): NormalizedResource<AnnotatorRelationship> => state.relationship,
    getRuleIdentifiers,
    getRuleConfigId,
  ],
  (config, relationshipState, identifiers, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let identifierId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;

        const identifier = identifiers.find((element) => element.identifier_uuid === annotatorId);
        identifierId = identifier?.identifier?.identifier_uuid;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (identifiers != null && 'annotatorId' in configB) {
                  const actualIdentifier = identifiers.find(
                    (element) => element.identifier_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualIdentifier?.identifier?.name}`,
                    deleted: !!(actualIdentifier && actualIdentifier.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the identifier name
      let ruleName = '';
      let deleted = false;
      if (!identifiers) {
        ruleName = name;
      } else if (identifiers != null) {
        const actualIdentifier = identifiers.find(
          (element) => element.identifier_uuid === annotatorId
        );
        if (actualIdentifier && annotatorId) {
          if (actualIdentifier && actualIdentifier.identifier == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualIdentifier.deleted_at == null) {
            ruleName = actualIdentifier.identifier?.name ?? '';
          } else {
            ruleName = actualIdentifier.identifier?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} identifier was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          identifierId,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

export const getCompareConfigRuleAsArray: Selector<MRuleConfig[]> = createSelector(
  [
    (state: GlobalState): NormalizedResource<MRuleConfigNode> => state.config.compareItems,
    (state: GlobalState): NormalizedResource<AnnotatorRelationship> =>
      state.config.compareRelationships,
    getCompareRuleIdentifiers,
    (state: GlobalState): string => state.config.compareRule?.config.uuid || '',
  ],
  (config, relationshipState, identifiers, rootConfigId) => {
    const traverseTree = (curIdNode: UUID, level: number, parent: UUID[]): MRuleConfig[] => {
      const curNode = config[curIdNode];
      if (!curNode) {
        return [];
      }
      const { id, name, description, color } = curNode;

      let negated;
      let mode_of_speech: ModeOfSpeech | undefined;
      let annotatorId: UUID | undefined;
      let identifierId: UUID | undefined;
      let modifiers;
      let nodeGroups;

      if (curNode.typeOfConfig === 'ANNOTATION_MATCH') {
        mode_of_speech = curNode.mode_of_speech;
        negated = curNode.negated;
        annotatorId = curNode.annotatorId;

        const identifier = identifiers.find((element) => element.identifier_uuid === annotatorId);
        identifierId = identifier?.identifier?.identifier_uuid;
      } else {
        modifiers = curNode.modifiers;
        nodeGroups = curNode.groups;
      }
      const relationship: MRuleConfigRelationship[] = [];

      if (curNode.parent != null) {
        const parentNode = config[curNode.parent];
        const curRelationship = 'relationship' in parentNode ? parentNode.relationship : null;

        if (curRelationship != null) {
          curRelationship.forEach((key) => {
            const cur = relationshipState[key];

            if (cur && cur.annotation_a != null && cur.annotation_b != null) {
              const annotatorAId = cur.annotation_a.id;
              const annotatorBId = cur.annotation_b.id;

              if (annotatorAId === id && config[annotatorBId]) {
                const configB = config[annotatorBId];
                if (identifiers != null && 'annotatorId' in configB) {
                  const actualIdentifier = identifiers.find(
                    (element) => element.identifier_uuid === configB.annotatorId
                  );
                  relationship.push({
                    id: cur.id,
                    name: `${cur.type} ${actualIdentifier?.identifier?.name}`,
                    deleted: !!(actualIdentifier && actualIdentifier.deleted_at != null),
                  });
                }
              }
            }
          });
        }
      }

      // FIXME: Create a function to process the identifier name
      let ruleName = '';
      let deleted = false;
      if (!identifiers) {
        ruleName = name;
      } else if (identifiers != null) {
        const actualIdentifier = identifiers.find(
          (element) => element.identifier_uuid === annotatorId
        );
        if (actualIdentifier && annotatorId) {
          if (actualIdentifier && actualIdentifier.identifier == null) {
            ruleName = `Identifier doesn't exist ${annotatorId}`;
            deleted = true;
          } else if (actualIdentifier.deleted_at == null) {
            ruleName = actualIdentifier.identifier?.name ?? '';
          } else {
            ruleName = actualIdentifier.identifier?.name ?? '';
            deleted = true;
            // @ts-ignore
            if (config.lastChangedNode === annotatorId) {
              Sentry.captureMessage(`${annotatorId} identifier was deleted`);
            }
          }
        } else {
          ruleName = name;
        }
      }

      const curItem: MRuleConfig[] = [
        {
          id,
          name: ruleName,
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { description } : {}),
          ...(curNode.typeOfConfig !== 'ANNOTATION_MATCH' ? { color } : {}),
          level,
          parent,
          deleted,
          relationship,
          identifierId,
          modifiers,
          nodeGroups,
          ...(curNode.typeOfConfig === 'ANNOTATION_MATCH'
            ? { negated, mode_of_speech, annotatorId }
            : {}),
        },
      ];

      const groups = 'groups' in curNode ? curNode.groups : [];
      return groups.reduce(
        (curArray, nextNodeId) =>
          traverseTree(nextNodeId, level + 1, [...parent, id]).concat(curArray),
        curItem
      );
    };
    return traverseTree(rootConfigId || '', 0, []).reverse();
  }
);

type RuleChangeWithIndex = RuleChange & { index: number };

export type ArrowChange = {
  id: string;
  leftId: string;
  leftIndex: number;
  leftCount: number;
  rightId: string;
  rightIndex: number;
  rightCount: number;
  type: 'add' | 'delete';
};

// Find index including relationships. Mostly for ui stuff like arrows
const findIndex = (array: MRuleConfigWithChanges[], changedNodeId: string): number => {
  let tempIndex = 0;
  let found = 0;
  array.forEach((node) => {
    if (node.id === changedNodeId) {
      found = tempIndex;
    } else {
      tempIndex += 1;
      const relationships = node.relationship;
      if (relationships) {
        relationships.forEach((r) => {
          if (r.id === changedNodeId) {
            found = tempIndex;
          }
          tempIndex += 1;
        });
      }
    }
  });

  return found;
};

// Includes relationships when calculating length - Could get relationship out of the node and make them a regular item later
// const getTrueLength = (array: MRuleConfigWithChanges[]): number => {
//   let tempIndex = 0;

//   array.forEach((node) => {
//     tempIndex += 1;
//     const relationships = node.relationship;
//     if (relationships) {
//       tempIndex += relationships.length;
//     }
//   });

//   return tempIndex;
// };

// Get index of last item in a parent. For inserting purposes
const getMaxIndexInParent = (array: MRuleConfigWithChanges[], nodeId: string): number => {
  const index = array.findIndex((node) => node.id === nodeId);
  let temp = index + 1;

  for (let i = index + 1; i < array.length; i += 1) {
    const tempNode = array[i];
    if (tempNode.parent.includes(nodeId)) {
      temp += 1;
      const relationships = tempNode.relationship;
      if (relationships) {
        // temp += relationships.length;
      }
    } else {
      break;
    }
  }

  return temp;
};

// Apply the change to node and children recursivelly. Return amount of nodes the change applies to. Just in case we need it
const makeChanges = (
  nodeId: string,
  array: MRuleConfigWithChanges[],
  change: RuleChange['kind'],
  count = 1
): number => {
  const index = array.findIndex((node) => node.id === nodeId);
  const node = array[index];

  if (!node) return 0;

  node.changed = change;

  if (node.nodeGroups && node.nodeGroups.length > 0) {
    node.nodeGroups.forEach((nG) => {
      makeChanges(nG, array, change, count);
    });
    return count + node.nodeGroups?.length;
  }

  return count;
};

export const getModifiedArray: Selector<MRuleConfigWithChanges[]> = (state) => {
  // Getting the regular configs.
  const arrayOne = (getCompareConfigRuleAsArray(state) as MRuleConfigWithChanges[]).map((n) => ({
    ...n,
  }));
  let arrayTwo = (getConfigRuleAsArray(state) as MRuleConfigWithChanges[]).map((n) => ({ ...n }));

  // True lenght includes relationships.
  // const arrayOneTrueLength = getTrueLength(arrayOne);
  // const arrayTwoTrueLength = getTrueLength(arrayOne);

  // All changes
  const changesArray = state.config.rulesDiff;

  // Changes that are changes
  const pureChanges = changesArray.filter((c) => c.kind === 'change');

  // Changes that are add or delete
  const combinedChanges = changesArray
    .filter((c) => c.kind === 'add' || c.kind === 'delete')
    .filter((c) => isNumber(c.path[c.path.length - 1]));

  // Need to index the changes according to where they should go so we can sort later
  const combinedIndexedChanges: RuleChangeWithIndex[] = combinedChanges.map((change) => {
    let index = 0;
    if (change.kind === 'add') {
      if (isObject(change.new)) {
        const changedNodeId = change.new.uuid;
        index = findIndex(arrayTwo, changedNodeId);
      }
    } else if (isObject(change.old)) {
      const changedNodeId = change.old.uuid;
      index = findIndex(arrayOne, changedNodeId);
    }

    return { ...change, index };
  });

  // Sorting the changes makes it less likely that arrow cross each other
  combinedIndexedChanges.sort((a, b) => {
    if (a.index < b.index) {
      return -1;
    }

    if (a.index > b.index) {
      return 1;
    }
    if (a.kind === 'delete') {
      return -1;
    }
    return 1;
  });

  // const arrows: ArrowChange[] = [];

  // Go through changes and do several things
  combinedIndexedChanges.forEach((change) => {
    let changedNodeId = '';
    // let index = 0;
    // let count = 0;

    // In add/delete id is the parent id
    const parentId = change.uuid;
    // const parentNode = arrayOne.find((n) => n.id === parentId);

    // For add info is in change.new. For delete info is in change.old
    // Also for add change applies to arrayTwo and insert space in arrayOne. Delete is the other way around
    if (change.kind === 'add') {
      if (isObject(change.new)) {
        changedNodeId = change.new.uuid;
        // index = findIndex(arrayTwo, changedNodeId);
        const node = arrayTwo.find((n) => n.id === changedNodeId);

        // If node exist apply change recursivelly. If not then is a relationship, find it and apply change
        if (node) {
          makeChanges(changedNodeId, arrayTwo, change.kind);
        } else {
          arrayTwo.forEach((n) => {
            const relationships = n.relationship;
            if (relationships) {
              relationships.forEach((r) => {
                if (r.id === changedNodeId) {
                  // @ts-ignore
                  r.changed = 'add';
                }
              });
            }
          });
          // count = 1;
        }
      }
    } else if (isObject(change.old)) {
      changedNodeId = change.old.uuid;
      // index = findIndex(arrayOne, changedNodeId);
      const node = arrayOne.find((n) => n.id === changedNodeId);

      if (node) {
        makeChanges(changedNodeId, arrayOne, change.kind);
      } else {
        arrayOne.forEach((n) => {
          const relationships = n.relationship;
          if (relationships) {
            relationships.forEach((r) => {
              if (r.id === changedNodeId) {
                // @ts-ignore
                r.changed = 'delete';
              }
            });
          }
        });

        // count = 1;
      }
    }

    // Empty rows are inserted as the last child of the parent.
    if (change.kind === 'delete') {
      const idx = arrayOne.findIndex((t) => t.id === changedNodeId);
      const max = getMaxIndexInParent(arrayOne, changedNodeId);

      const tempArr = arrayOne.slice(idx, max).map((n) => ({ ...n, original: true }));

      if (tempArr.length > 0) {
        const ind = getMaxIndexInParent(arrayTwo, parentId);
        const parentIndex = arrayTwo.findIndex((n) => n.id === parentId);

        arrayTwo[parentIndex] = {
          ...arrayTwo[parentIndex],
          nodeGroups: [...(arrayTwo[parentIndex]?.nodeGroups || []), changedNodeId],
        };

        // @ts-ignore
        arrayTwo = [...arrayTwo.slice(0, ind), ...tempArr, ...arrayTwo.slice(ind)];
      }
    }
  });

  // For regular changes only thing to do is find the nodes and add the change so ui can render it
  // Not many different type of changes now - Need to support more using an array - Need to support multiple in same node also using an array
  const operatorChanges = pureChanges.filter((change) => change.path.includes('operator'));
  const descriptionChanges = pureChanges.filter((change) => change.path.includes('description'));
  const operandChanges = pureChanges.filter((change) => change.path.includes('operands'));

  operandChanges.forEach((change) => {
    const operandId = typeof change.old !== 'string' ? change.old.uuid : '';
    const modifyIndex = arrayTwo.findIndex((node) => node.id === operandId);
    const maxModify = getMaxIndexInParent(arrayTwo, operandId);

    arrayTwo[modifyIndex] = { ...arrayTwo[modifyIndex], changed: 'change' };
    for (let i = modifyIndex + 1; i < maxModify; i += 1) {
      arrayTwo[i] = { ...arrayTwo[i], changed: 'change' };
    }

    const insertIndex = arrayOne.findIndex((node) => node.id === operandId);
    const maxInsert = getMaxIndexInParent(arrayOne, operandId);

    const tempArr = arrayOne.slice(insertIndex, maxInsert).map((n) => ({
      ...n,
      changed: 'change',

      original: true,
      id: `${n.id}-original`,
      nodeGroups: n.nodeGroups?.map((nodeGroup) => `${nodeGroup}-original`),
    }));

    // @ts-ignore
    arrayTwo = [...arrayTwo.slice(0, maxModify), ...tempArr, ...arrayTwo.slice(maxModify)];
  });

  operatorChanges.forEach((change) => {
    const modifyIndex = arrayTwo.findIndex((node) => node.id === change.uuid);
    const maxModify = getMaxIndexInParent(arrayTwo, change.uuid);

    arrayTwo[modifyIndex] = { ...arrayTwo[modifyIndex], changed: 'change', change: 'operator' };
    for (let i = modifyIndex + 1; i < maxModify; i += 1) {
      arrayTwo[i] = { ...arrayTwo[i], changed: 'change' };
    }

    const insertIndex = arrayOne.findIndex((node) => node.id === change.uuid);
    const maxInsert = getMaxIndexInParent(arrayOne, change.uuid);

    const tempArr = arrayOne.slice(insertIndex, maxInsert).map((n) => ({
      ...n,
      changed: 'change',

      original: true,
      id: `${n.id}-original`,
      nodeGroups: n.nodeGroups?.map((nodeGroup) => `${nodeGroup}-original`),
    }));

    tempArr[0] = { ...tempArr[0], change: 'operator' };

    // @ts-ignore
    arrayTwo = [...arrayTwo.slice(0, maxModify), ...tempArr, ...arrayTwo.slice(maxModify)];
  });

  descriptionChanges.forEach((change) => {
    const rightIndex = arrayTwo.findIndex((node) => node.id === change.uuid);
    const max = getMaxIndexInParent(arrayTwo, change.uuid);

    arrayTwo[rightIndex] = { ...arrayTwo[rightIndex], changed: 'change', change: 'description' };
    for (let i = rightIndex + 1; i < max; i += 1) {
      arrayTwo[i] = { ...arrayTwo[i], changed: 'change' };
    }
  });

  return arrayTwo;
};

export const getConfigRule: Selector<MRuleConfigWithChanges[]> = createSelector(
  [
    (state: GlobalState): boolean => state.config.compareMode,
    (state: GlobalState): string => state.ruleGroups.selectedCustomerGroup,
    getConfigRuleAsArray,
    getModifiedArray,
    getCompareConfigRuleAsArray,
  ],
  (compareMode, customerGroup, arrayTree, modifiedArray, productionArray) => {
    if (compareMode) {
      return modifiedArray;
    }
    if (customerGroup === 'production') {
      return productionArray;
    }
    return arrayTree;
  }
);

type IdentifiersDiff = {
  added: string[];
  removed: RuleIdentifier[];
  changed: string[];
};

export const getCompareIdentifiers: Selector<IdentifiersDiff> = createSelector(
  [getSelectedRuleIdentifiers, getCompareRuleIdentifiers],
  (identifiers, compareIdentifiers) => {
    const identifiersDiff: IdentifiersDiff = {
      added: [],
      removed: [],
      changed: [],
    };

    identifiers.forEach((i) => {
      const sameIdentifier = compareIdentifiers.find(
        (ci) => ci.identifier_uuid === i.identifier_uuid
      );
      if (!sameIdentifier) {
        identifiersDiff.added.push(i.identifier_uuid);
      }
    });

    compareIdentifiers.forEach((i) => {
      const sameIdentifier = identifiers.find((ci) => ci.identifier_uuid === i.identifier_uuid);
      if (!sameIdentifier) {
        identifiersDiff.removed.push(i);
      }
    });

    return identifiersDiff;
  }
);

export const getCreateRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(createRuleGroupRequest.toString());

export const saveRuleRequestLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(saveRuleRequest.toString());

export const getSelectedRule: Selector<MRuleRevision | null> = (state) =>
  state.ruleGroups.selectedRule;

export const getRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchSingleRuleGroupRequest.toString());

export const getSelectedIdentifiers: Selector<string[]> = (state) =>
  state.ruleGroups.selectedIdentifiers;

export const getCurrentTestRuleId: Selector<string> = (state) => state.ruleGroups.testRuleId;

export const getRuleIdentifierPosition: Selector<string | null> = (state) =>
  state.ruleGroups.identifierPosition;

export const getToIdentifier: Selector<boolean> = (state) => state.ruleGroups.toIdentifier;

export const getPreviewRuleGroupLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(previewRuleGroupRequest.toString());

export const getRulesViolated: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.ruleGroups;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export const getRulesViolatedV2: Selector<{
  rulesViolated: RuleResults[];
  rulesPassed: RuleResults[];
}> = (state) => {
  const { test } = state.ruleGroups;

  const rulesViolated: RuleResults[] = [];
  const rulesPassed: RuleResults[] = [];
  if (test == null) {
    return { rulesViolated, rulesPassed };
  }
  const resultRules = test.graph_v2_rule_results;
  if (!resultRules || resultRules.length === 0) {
    return { rulesViolated, rulesPassed };
  }
  resultRules.forEach((e) => {
    (e.value ? rulesViolated : rulesPassed).push(e);
  });
  return { rulesViolated, rulesPassed };
};

export type CampaignRulesViolated = {
  label: string;
  id: string;
  rules: { label: string; id: string }[];
};

export const getCampaignsRulesViolatedRuleGroup: Selector<Record<string, CampaignRulesViolated>> = (
  state
) => {
  const { rulesViolated } = getRulesViolated(state);

  const rules: Record<string, CampaignRulesViolated> = {};

  rulesViolated.forEach((r) => {
    const { rule } = r;

    rules[rule.uuid] = {
      label: rule.name,
      id: rule.uuid,
      rules: [{ label: r.rule.name, id: r.rule.uuid }],
    };
  });

  return rules;
};

export const getCampaignsRulesViolatedRuleGroupV2: Selector<
  Record<string, CampaignRulesViolated>
> = (state) => {
  const { rulesViolated } = getRulesViolatedV2(state);

  const rules: Record<string, CampaignRulesViolated> = {};

  rulesViolated.forEach((r) => {
    const { rule } = r;

    rules[rule.uuid] = {
      label: rule.name,
      id: rule.uuid,
      rules: [{ label: r.rule.name, id: r.rule.uuid }],
    };
  });

  return rules;
};

export const getAnnotatedTestSentenceRuleGroupResult: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = createSelector([(state): GlobalState => state, getUseNewGraphs], (state, useNewGraphs) => {
  const { test } = state.ruleGroups;
  if (test == null) {
    return null;
  }
  const { objects, subjects } = test;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = test.annotations.map((a) => ({ ...a, highlight: true }));

  if (useNewGraphs) {
    return annotateCommunicationGroups(
      test.document.graphs,
      test.document.groups,
      annotations,
      null,
      normalizedMode
    ).lines;
  }

  return annotateCommunicationGroupsLegacy(
    // @ts-ignore
    test.document.groups,
    annotations,
    null,
    normalizedMode
  ).lines;
});

export const getAnnotatedTestSentenceRuleGroupResultV2: Selector<
  ReturnType<typeof annotateCommunicationGroups>['lines'] | null
> = createSelector([(state): GlobalState => state, getUseNewGraphs], (state, useNewGraphs) => {
  const { test } = state.ruleGroups;
  if (test == null) {
    return null;
  }

  const { v2_summary: summaryV2, v2_annotations: annotationsV2 } = test;

  if (!summaryV2 || !annotationsV2) {
    return null;
  }

  const summary = Array.isArray(summaryV2) ? summaryV2[0] : summaryV2;

  const { objects, subjects } = summary;
  const normalizedMode = remapModesOfSpeech(subjects, objects);

  const annotations = annotationsV2.map((a) => ({ ...a, highlight: true }));

  if (useNewGraphs) {
    return annotateCommunicationGroups(
      summary.document.graphs,
      summary.document.groups,
      annotations,
      null,
      normalizedMode
    ).lines;
  }

  return annotateCommunicationGroupsLegacy(
    summary.document.groups,
    annotations,
    null,
    normalizedMode
  ).lines;
});

export const getRuleGroupTest: Selector<TestCommunication | null> = (state) =>
  state.ruleGroups.test;

export const getTriggeredNodes: Selector<string[]> = createSelector(
  [
    (state): GlobalState['config'] => state.config,
    (state): GlobalState['ruleGroups'] => state.ruleGroups,
  ],
  (config, ruleGroup) => {
    const triggeredNodes: string[] = [];
    // if something has been changed and not saved, we don't want to trigger this
    if (config.added.length > 0 || config.modified.length > 0) {
      return triggeredNodes;
    }
    if (
      !ruleGroup ||
      !ruleGroup.test ||
      !ruleGroup.test.rule_results ||
      ruleGroup.test.rule_results.length === 0
    ) {
      return triggeredNodes;
    }

    const { context } = ruleGroup.test.rule_results[0];
    if (!context.operands) {
      return triggeredNodes;
    }

    const traverseTree = (arr: OperandsValue, node: string): void => {
      if (!arr) return;

      if (arr[0] === true) {
        triggeredNodes.push(node);
      }
      if (Object.keys(arr[1]).length === 0) {
        return;
      }
      Object.keys(arr[1]).forEach((key) => {
        traverseTree(arr[1][key], key);
      });
    };

    const rootNode = Object.keys(context.operands)[0];
    traverseTree(context.operands[rootNode], rootNode);

    return triggeredNodes;
  }
);

export const getTriggeredNodesV2: Selector<string[]> = createSelector(
  [
    (state): GlobalState['config'] => state.config,
    (state): GlobalState['ruleGroups'] => state.ruleGroups,
  ],
  (config, ruleGroup) => {
    const triggeredNodes: string[] = [];
    // if something has been changed and not saved, we don't want to trigger this
    if (config.added.length > 0 || config.modified.length > 0) {
      return triggeredNodes;
    }
    if (
      !ruleGroup ||
      !ruleGroup.test ||
      !ruleGroup.test.graph_v2_rule_results ||
      ruleGroup.test.graph_v2_rule_results.length === 0
    ) {
      return triggeredNodes;
    }

    const { context } = ruleGroup.test.graph_v2_rule_results[0];
    if (!context.operands) {
      return triggeredNodes;
    }

    const traverseTree = (arr: OperandsValue, node: string): void => {
      if (!arr) return;

      if (arr[0] === true) {
        triggeredNodes.push(node);
      }
      if (Object.keys(arr[1]).length === 0) {
        return;
      }
      Object.keys(arr[1]).forEach((key) => {
        traverseTree(arr[1][key], key);
      });
    };

    const rootNode = Object.keys(context.operands)[0];
    traverseTree(context.operands[rootNode], rootNode);

    return triggeredNodes;
  }
);

export const getFetchRuleCustomersLoading: Selector<boolean> = (state) =>
  state.ruleGroups.loading.includes(fetchRuleCustomersRequest.toString());

export const getRuleCustomers: Selector<Customer[]> = (state) => state.ruleGroups.ruleCustomers;

export const getRuleCategory: Selector<Category | null> = (state) => state.ruleGroups.ruleCategory;

export const getShowRuleUtilization: Selector<boolean> = (state) =>
  state.ruleGroups.showUtilization;

export const getShowV2Graph: Selector<boolean> = (state) => state.ruleGroups.showV2;

export const getSelectedCustomerGroup: Selector<string> = (state) =>
  state.ruleGroups.selectedCustomerGroup;
