import config from '@config';

// to have the proper types on the client this can't have a type, if debugging
// is needed ClientSpec can be used as the type
const V2APISpec = {
  base: config.api.v2,
  logs: false,

  headers: {
    'x-litlingo-auth-no-redirect': 'true',
    'block-overwrite': 'true',
    'cache-control': 'no-cache',
  },

  resources: {
    users: {
      path: '/users',
      include: [],
      extras: {
        me: {
          path: '/me',
          method: 'GET',
        },
        customers: {
          path: '/me/customers',
          method: 'GET',
        },
        metricCustomers: {
          path: '/metric/customers',
          method: 'GET',
        },
      },
    },
    categories: {
      path: '/categories',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {},
    },
    identifiers: {
      path: '/identifiers',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {
        getRevisions: {
          path: '/revisions',
          method: 'GET',
        },
        getIdentifierRevisions: {
          path: '/{identifierId}/revisions',
          method: 'GET',
        },
        getRevision: {
          path: '/revisions/{revisionsId}',
          method: 'GET',
        },
        getCustomers: {
          path: '/revisions/{revisionsId}/customers',
          method: 'GET',
        },
        getCategories: {
          path: '/revisions/{revisionsId}/categories',
          method: 'GET',
        },
        upsertIdentifierRevision: {
          path: '/{identifierId}',
          method: 'PUT',
        },
        bulkDelete: {
          path: '/bulk/delete',
          method: 'POST',
        },
        cloneIdentifier: {
          path: '/{identifierId}/clone',
          method: 'POST',
        },
        merge: {
          path: '/merge',
          method: 'POST',
        },
      },
    },
    models: {
      path: '/models',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {
        getCategories: {
          path: '/{modelId}/categories',
          method: 'GET',
        },
        bulkUpdateKind: {
          path: '/bulk_update_kind',
          method: 'POST',
        },
        cloneModel: {
          path: '/{modelId}/clone',
          method: 'POST',
        },
      },
    },
    ruleGroups: {
      path: '/rules',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {
        getLatestRevision: {
          path: '/{ruleId}/head',
          method: 'GET',
        },
        getRevisions: {
          path: '/revisions',
          method: 'GET',
        },
        getRevision: {
          path: '/revisions/{revisionsId}',
          method: 'GET',
        },
        getCustomers: {
          path: '/revisions/{revisionsId}/customers',
          method: 'GET',
        },
        getRuleRevisions: {
          path: '/{ruleId}/revisions',
          method: 'GET',
        },
        upsertRuleRevision: {
          path: '/{ruleId}',
          method: 'PUT',
        },
        preview: {
          path: '/revisions/{revisionId}/preview',
          method: 'POST',
        },
        previewGroup: {
          path: '/{ruleGroupId}/preview',
          method: 'POST',
        },
        changelog: {
          path: '/{revisionId}/audit-logs',
          method: 'GET',
        },
        cloneRule: {
          path: '/{ruleId}/clone',
          method: 'POST',
        },
        diffRevisions: {
          path: '/{revisionIdOne}/diff/{revisionIdTwo}',
          method: 'POST',
        },
        promoteRule: {
          path: '/{groupId}/heads/{headId}/promote',
          method: 'POST',
        },
      },
    },
    communications: {
      path: '/communications',
      include: [],
      extras: {
        preview: {
          path: '/preview',
          method: 'POST',
        },
        document: {
          path: '{communicationId}/document',
          method: 'GET',
        },
      },
    },
    ModelMetrics: {
      path: '/model_metrics',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {},
    },
  },
} as const;

export default Object.freeze(V2APISpec);
