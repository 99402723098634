import {
  changeLanguageMatcherType,
  editLanguageMatcher,
  fetchLanguageMatcherTypes,
} from 'actions/identifier';
import WarningMsg from 'components/WarningMsg';
import { matcherTypeLabelMap } from 'constants/annotator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getActiveLanguageMatcher,
  getActiveLanguageMatcherId,
  getLanguageMatcherTypes,
  getOriginalActiveLanguageMatcher,
  saveIdentifierRequestLoading,
  unsavedChanges,
} from 'selectors/identifier';
import { useSelector } from 'store';
import UserNotificationMsgs from 'utils/userNotificationMsgs';
import ChooseCombinationType from './ChooseCombinationType';

type LanguageMatcherType = {
  isSaved: boolean;
};

const LanguageMatcherForm: React.FC<LanguageMatcherType> = ({ isSaved }) => {
  const dispatch = useDispatch();

  const unsaved = useSelector(unsavedChanges);
  const activeLanguageMatcher = useSelector(getActiveLanguageMatcher);
  const originalActiveLanguageMatcher = useSelector(getOriginalActiveLanguageMatcher);
  const activeLanguageMatcherId = useSelector(getActiveLanguageMatcherId);
  const isLoadingSaveIdentifier = useSelector(saveIdentifierRequestLoading);
  const combinations = useSelector(getLanguageMatcherTypes);

  const [combinationSelection, setCombinationSelection] = useState<string>('');

  useEffect(() => {
    if (activeLanguageMatcher && activeLanguageMatcher.isNew) {
      const input = document.getElementById('name');
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguageMatcherId]);

  useEffect(() => {
    if (!activeLanguageMatcher) return;
    if (activeLanguageMatcher.type) {
      setCombinationSelection(activeLanguageMatcher.type);
    }
  }, [activeLanguageMatcher]);

  useEffect(() => {
    dispatch(fetchLanguageMatcherTypes());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onEditLanguageMatcher = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!activeLanguageMatcher.uuid || e.target.value === null) return;
    dispatch(
      editLanguageMatcher({
        languageMatcherId: activeLanguageMatcher.uuid,
        data: { [e.target.name]: e.target.value },
      })
    );
  };

  const handleChangeType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setCombinationSelection(e.target.value);
    const defaultValues: { [key: string]: string | number } = {};
    Object.entries(combinations[e.target.value as keyof typeof combinations]).forEach(
      ([key, field]) => {
        if (field != null && field.default) {
          defaultValues[key] = field.default;
        }
      }
    );
    dispatch(
      changeLanguageMatcherType({
        languageMatcherId: activeLanguageMatcher.uuid,
        type: e.target.value,
        ...defaultValues,
      })
    );
  };

  const isLanguageMatcherNew =
    !activeLanguageMatcher || (activeLanguageMatcher && activeLanguageMatcher.isNew);

  return (
    <>
      <div className="pt-4 px-6 flex flex-row justify-between">
        <div />
        <WarningMsg
          message={UserNotificationMsgs.unsavedChanges}
          showLoading={isLoadingSaveIdentifier}
          showWarning={unsaved}
          showSavedMsg={isSaved}
        />
      </div>
      <div className="px-6 flex flex-row gap-4">
        <label htmlFor="name" className="flex flex-col gap-1 text-body w-1/2">
          <div className="flex flex-row">
            <span>Name</span>
            <span className="text-litlingo-alert">*</span>
          </div>
          <input
            name="name"
            id="name"
            data-testid="matcher-name-input"
            value={activeLanguageMatcher.name}
            className={`form-input w-full py-2 px-4 border border-litlingo-gray-2 rounded-lg text-body focus:outline-none ${
              activeLanguageMatcher.name !== originalActiveLanguageMatcher?.name
                ? 'bg-litlingo-focus-area-background'
                : ''
            }`}
            onChange={onEditLanguageMatcher}
          />
        </label>

        <div className="w-1/2">
          <label htmlFor="keyword" className="flex flex-col gap-1 text-body w-60">
            <div>Type</div>
            {!isLanguageMatcherNew ? (
              <div className="py-2 px-4 w-full bg-litlingo-gray-0.5 border border-litlingo-gray-2 rounded-lg">
                {matcherTypeLabelMap[combinationSelection as keyof typeof matcherTypeLabelMap]}
              </div>
            ) : (
              <div>
                <select
                  value={combinationSelection}
                  onChange={handleChangeType}
                  id="Mode Of Speech"
                  data-testid="select-language-matcher-type"
                  className="block form-select w-full border border-litlingo-gray-2 disabled:bg-litlingo-gray-2"
                  disabled={!isLanguageMatcherNew}
                >
                  {Object.keys(combinations).map((combination) => (
                    <option key={combination} value={combination}>
                      {combination}
                    </option>
                  ))}
                </select>
                {combinationSelection === 'keyword' ? (
                  <span className="text-xs">WARNING: Please Do Not use!!</span>
                ) : (
                  ''
                )}
                <p className="mt-2 text-sm text-gray-500">
                  {combinations[combinationSelection as keyof typeof combinations] &&
                    combinations[combinationSelection as keyof typeof combinations].description}
                </p>
              </div>
            )}
          </label>
        </div>
      </div>
      <div className="flex pt-4 px-6 justify-between">
        <div className="w-full">
          <ChooseCombinationType activeLanguageMatcher={activeLanguageMatcher} />
        </div>
      </div>
    </>
  );
};

export default LanguageMatcherForm;
