import { createAction } from '@reduxjs/toolkit';
import type { API, Category, ErrorObject, UUID } from 'types';

type FetchAllCategoriesPayload = {
  limit?: string;
  relationships?: string[];
  selectable_fields?: string[];
  rule_revision_uuids?: string;
  rule_uuids?: string[];
  customer_uuids?: string[];
  include_staging?: string;
};
export type FetchAllCategoriesSuccessPayload = API.WrappedAPIResponse<Category>;

export const fetchAllCategories = createAction<FetchAllCategoriesPayload>('CATEGORIES/FETCH');
export const fetchAllCategoriesRequest = createAction('CATEGORIES/FETCH_REQUEST');
export const fetchAllCategoriesSuccess = createAction<API.WrappedAPIResponse<Category>>(
  'CATEGORIES/FETCH_SUCCESS'
);
export const fetchAllCategoriesFailure = createAction<ErrorObject>('CATEGORIES/FETCH_FAILURE');
export const fetchAllCategoriesFulfill = createAction('CATEGORIES/FETCH_FULFILL');

export const fetchSingleCategory = createAction<{ categoryId: UUID }>('CATEGORIES/FETCH_SINGLE');
export const fetchSingleCategoryRequest = createAction('CATEGORIES/FETCH_SINGLE_REQUEST');
export const fetchSingleCategoriesuccess = createAction<Category>(
  'CATEGORIES/FETCH_SINGLE_SUCCESS'
);
export const fetchSingleCategoryFailure = createAction<ErrorObject>(
  'CATEGORIES/FETCH_SINGLE_FAILURE'
);
export const fetchSingleCategoryFulfill = createAction('CATEGORIES/FETCH_SINGLE_FULFILL');

export type CreateCategoryPayload = {
  modelId: UUID;
  ruleId?: UUID;
  ruleName?: string;
  customerId: UUID;
  outcomeId?: UUID;
  redirect?: string;
  fromRule?: boolean;
  scope?: boolean;
};

export const createCategory = createAction<CreateCategoryPayload>('CATEGORIES/CREATE');
export const createCategoryRequest = createAction('CATEGORIES/CREATE_REQUEST');
export const createCategoriesuccess = createAction<Category>('CATEGORIES/CREATE_SUCCESS');
export const createCategoryFailure = createAction<ErrorObject>('CATEGORIES/CREATE_FAILURE');
export const createCategoryFulfill = createAction('CATEGORIES/CREATE_FULFILL');

export const createCategoryRule = createAction<CreateCategoryPayload>('CATEGORIES/CREATE_RULE');

export type DeleteCategoryPayload = {
  id: UUID;
  redirect?: boolean;
  ruleId?: UUID;
  modelId?: UUID;
  outcome?: boolean;
};

export const deleteCategory = createAction<DeleteCategoryPayload>('CATEGORIES/DELETE');
export const deleteCategoryRequest = createAction<string>('CATEGORIES/DELETE_REQUEST');
export const deleteCategoriesuccess = createAction<string>('CATEGORIES/DELETE_SUCCESS');
export const deleteCategoryOutcomeSuccess = createAction<{ id: UUID }>(
  'CATEGORIES/DELETE_OUTCOME_SUCCESS'
);
export const deleteCategoryFailure = createAction<ErrorObject>('CATEGORIES/DELETE_FAILURE');
export const deleteCategoryFulfill = createAction('CATEGORIES/DELETE_FULFILL');

export type UpsertCategoryPayload = Partial<Category> & {
  uuid?: string;
  redirect?: boolean;
};

export const upsertCategory = createAction<UpsertCategoryPayload>('CATEGORIES/UPSERT');
export const upsertCategoryRequest = createAction('CATEGORIES/UPSERT_REQUEST');
export const upsertCategoriesuccess = createAction<Category>('CATEGORIES/UPSERT_SUCCESS');
export const upsertCategoryFailure = createAction<ErrorObject>('CATEGORIES/UPSERT_FAILURE');
export const upsertCategoryFulfill = createAction('CATEGORIES/UPSERT_FULFILL');
